import zipkin from "zipkin";
import {
    BookingInfoPropTypes,
    BookingListItemPropTypes,
    BookingListResultPropTypes,
    BookingOptionsPropTypes,
    BookingSearchCriteriaPropTypes,
    BookingSearchOptionsPropTypes,
    CompanyDetailsResponse,
    CustomVoucherPropTypes,
    DestinationDestinationPropTypes,
    DestinationsResultPropTypes,
    GooglePlace,
    HotelAvailabilityCriteriaPropTypes,
    HotelBookingConfirmationInfoPropTypes,
    HotelBOokingRoomInfoPropTypes,
    HotelDetails,
    HotelOfferPropTypes,
    HotelSearchCriteriaPropTypes,
    HotelSearchResultsFiltersPropTypes,
    HotelStateFormData,
    InsufficientAuthenticationType,
    InvoiceRecipientPropTypes,
    LocaleResult,
    LoginErrorType,
    MultiPaymentBookingOptionsPropTypes,
    NationalityPropTypes,
    NationalityResultPropTypes,
    Notifications,
    OverPaymentOptionsResponsePropTypes,
    PaymentTransferTypePropTypes,
    PaymentVoucherPropTypes,
    PinnedOffer, ProviderWithOfferPropTypes,
    RecentDestinationPropTypes,
    RecentSearchPropTypes,
    ReduxActionRedirectType,
    ReduxModalType,
    ReservationChangesPropTypes,
    SavedSearchPropTypes,
    SimpleBookingPropTypes,
    SimpleHotelOfferPropTypes,
    TransferAvailabilityCriteriaPropTypes,
    TransferBookingInfoPropTypes,
    TransferDestinationPropTypes,
    TransferDestinationsResultPropTypes,
    TransferOfferPropTypes,
    TransferSearchCriteriaPropTypes,
    TransferStateFormData,
    UserDetailsPropTypes, WebBankAccount,
    WebBankLinkOptionPropTypes,
    WebHotelBookingDetails,
    WebHotelBookingRoomPropTypes,
    WebPaymentPromotion,
    WebPaymentProvider,
    WebProviderPaymentPropTypes,
    WebSplitPricePart,
    WebTransferBookingDetails
} from "./PropTypeObjects"
import {Location} from "react-router-dom"
import Currency from "@hotelston_web_frontend_parent/src/constants/currency";
import {HotelFilterStatistics} from "proptypes/HotelSearchObjects";

export type HotelSearchFormParams = {
    logRequests?: boolean;
    multiprovider?: boolean;
    ignoreSelectBestOffers?: boolean;
};

export type ReduxHotelSearchPropTypes = {
    formData: HotelSearchCriteriaPropTypes;
    formParams: HotelSearchFormParams;
    stateFormData: HotelStateFormData;
    destinationInput: string;
    nationalityInput?: string;
    submitedSearch?: boolean;
    currentDestination?: DestinationDestinationPropTypes | RecentDestinationPropTypes
    isSearching: boolean;
    isUpdating: boolean;
    error?: string;
    recentSearches: RecentSearchPropTypes[];
    savedSearches: SavedSearchPropTypes[];
    showHotelId?: boolean;
};

export type ReduxHotelSearchResultsPropTypes = {
    filters: HotelSearchResultsFiltersPropTypes;
    activeFilters: string[];
    stateFilters: HotelSearchResultsFiltersPropTypes;
    activeStateFilters: string[];
    maxPrice?: number;
    maxAllowed?: number;
    size: number;
    submitedFilters?: boolean;
    hotelDetailsCache: HotelDetails[];
    afterAllFilters: SimpleHotelOfferPropTypes[];
    sortedHotelIds: number[];
    dataForFilters: SimpleHotelOfferPropTypes[];
    hotelOffers: HotelOfferPropTypes[];
    allHotelOffers: SimpleHotelOfferPropTypes[];
    allProvidersWithOffers: ProviderWithOfferPropTypes[];
    statistics?: HotelFilterStatistics;
    allResultsCount: number;
    firstTimeMounted: boolean;
    pageSizeOptions: { value: number, label: number }[];
    pageNumber: number;
    pagesCount: number;
    sortBy: {
        label: string;
        value: string;
    };
    sortByOptions: {
        value: string;
    }[];
    mapFilter: boolean;
    favoriteFilter: boolean;
    isUpdating: boolean;
    requestingDetails: boolean;
    cxlUpdatingRoomIds: number[];
    view: string;
    error?: string;
};

export type ReduxNationalityPropTypes = {
    currentNationalitiesLocale: string;
    initialized: boolean;
    requesting: boolean;
    nationalities: NationalitiesPropTypes;
    error?: string;
};

export type NationalitiesPropTypes = NationalityResultPropTypes & {
    currentNationality?: NationalityPropTypes;
};

export type ReduxTransferBookingPropTypes = {
    data?: {
        bookingReference?: string;
        cxlDate?: number;
        bookingDetails?: WebTransferBookingDetails;
        bookingInfo?: BookingInfoPropTypes;
        transferAvailabilityCriteria?: TransferAvailabilityCriteriaPropTypes;
        duplicateBookings?: SimpleBookingPropTypes[];
        transferBookingInfo?: TransferBookingInfoPropTypes;
        route?: string;
        errors?: string[];
    };
    transfer?: {
        transferId: number;
        transferName: string;
        pax: number;
    };
    smsLanguagesmsReminderLocaleInput?: string;
    savedPassengerDetails?: any;
    smsFee?: number;
    displaySmsFee?: number;
    firstTimeChanges?: boolean;
    allowedInBookingPages?: boolean;
    requestingValidation: boolean;
    requestingBooking: boolean;
    loadedData?: boolean;
    error?: string[];
    bookingOptionsParams?: {
        locale: string;
        currency: Currency;
    };
    allowBookSubmit: boolean;
};

export type ReduxTransferSearch = {
    submitedSearch?: boolean;
    isSearching?: boolean;
    isUpdating?: boolean;
    error?: string;
    saveSearch?: boolean;
    airportInput?: string;
    venueInput?: string;
    bookRoundtrip?: boolean;
    currentDestination?: TransferDestinationPropTypes;
    currentOrigin?: TransferDestinationPropTypes;
    formData: TransferSearchCriteriaPropTypes;
    stateFormData: TransferStateFormData;
    recentSearches?: RecentSearchPropTypes[];
    savedSearches?: SavedSearchPropTypes[];
};

export type ReduxTransferSearchResults = {
    transferOffers?: TransferOfferPropTypes[];
    firstTimeMounted?: boolean;
    allResultsCount: number;
    size: number;
    pageSizeOptions: {
        value: number;
        label: number;
    }[];
    pageNumber: number;
    sortBy: {
        value: string;
        label: string;
    };
    sortByOptions: {
        value: string;
    }[];
};

export type ReduxMyBookingsQuickFilterProps = {
    value?: string;
    label?: string;
    tooltipText?: string;
};

export type ReduxMyBookingsPropTypes = {
    data?: BookingListResultPropTypes;
    quickFilters: ReduxMyBookingsQuickFilterProps[];
    bookingOptions?: BookingSearchOptionsPropTypes;
    formData: BookingSearchCriteriaPropTypes;
    pageSize: number;
    pageNr: number;
    isSearching: boolean;
    optionsLoading: boolean;
    activeQuickFilters: string[];
    advancedFilters: boolean;
    searchSubmitted: boolean;
    error?: string[];
};

export type ReduxOverpaymentPropTypes = {
    error?: string[];
    requestingOptions: boolean;
    requestingUse: boolean;
    overpaymentOptions?: OverPaymentOptionsResponsePropTypes;
};

export enum ReduxPaymentTypePropTypes {
    HOTEL = "HOTEL",
    TRANSFER = "TRANSFER",
    MULTIBOOKING = "MULTIBOOKING",
    ANONYMOUS_MULTIBOOKING = "ANONYMOUS_MULTIBOOKING",
    SPLIT_PRICE_PART_PAYMENT = "SPLIT_PRICE_PART_PAYMENT"
}

export type ReduxDiscountCode = {
    code: string;
    valid?: boolean;
    discountSum?: number;
}

export type ReduxPaymentPropTypes = {
    type?: ReduxPaymentTypePropTypes;

    loading: boolean;
    splitPriceSessionInvalid?: boolean;
    splitPriceAllBookingsPaid?: boolean;
    showBusinessCredentials?: boolean;

    providerPayment?: WebProviderPaymentPropTypes;
    bookingOptions?: BookingOptionsPropTypes | MultiPaymentBookingOptionsPropTypes;

    selectedPaymentLink?: WebBankLinkOptionPropTypes;
    paymentVouchers?: PaymentVoucherPropTypes[];
    paymentPromotion?: WebPaymentPromotion;

    appliedDiscountAmount?: number;
    appliedWalletAmount?: number;

    appliedDiscountCode?: ReduxDiscountCode;

    transactionFee: number;
    displayTransactionFee: number;

    paymentProvider?: WebPaymentProvider;
    paymentTransferTypeEnum?: PaymentTransferTypePropTypes;
    customInvoice?: boolean;
    invoiceRecipient?: InvoiceRecipientPropTypes;

    paymentLinkToken?: string;

    agreeTerms?: boolean;

    voucherSum: {
        all: number;
        used: number;
    };
    customVoucher: CustomVoucherPropTypes;

    anonymousActiveOrders: SimpleBookingPropTypes[];
    anonymousOptionsFailure: boolean;

    splitPricePartSize?: number;
    splitPriceSessionId?: number;
    splitPricePartNo?: number;
    splitPricePaymentTransferType?: PaymentTransferTypePropTypes;
    splitPriceParts?: WebSplitPricePart[];
    selectedSplitPricePart?: WebSplitPricePart;
    splitPriceBookings?: BookingListItemPropTypes[];
    resumedSplitPriceSession?: boolean;

    errors?: string[];
};

export type ReduxHotelBookingPropTypes = {
    data?: {
        bookingInfo?: BookingInfoPropTypes;
        hotelAvailabilityCriteria?: HotelAvailabilityCriteriaPropTypes;
        duplicateBookings?: SimpleBookingPropTypes[];
        changes?: ReservationChangesPropTypes;
        hotelBookingInfo?: HotelBookingConfirmationInfoPropTypes;
        hotelBookingRooms?: WebHotelBookingRoomPropTypes[];
        route?: string;
        errors?: string[];
        showBusinessCredentials?: boolean;
    };
    bookingOptionsParams?: {
        locale: string;
        currency: Currency;
    };
    savedPassengerDetails?: HotelBOokingRoomInfoPropTypes[];
    firstTimeChanges: boolean;
    allowedInBookingPages: boolean;
    requestingOptions: boolean;
    requestingBooking: boolean;
    allowBookSubmit: boolean;
    requestingValidation: boolean;
    loadedData: boolean;
    errors?: string[];
};

export type ReduxDestinationsPropTypes = {
    searching: boolean;
    destinations?: DestinationsResultPropTypes;
    transferDestinations?: TransferDestinationsResultPropTypes;
    transferOrigins?: TransferDestinationsResultPropTypes;
    error?: string[];
};

export type ReduxModalsPropTypes = {
    currentActiveModal?: ReduxModalType;
    scrollLocked: boolean;
};

export type ReduxActionRedirectPropTypes = {
    mounted: boolean;
    actionRedirect?: ReduxActionRedirectType;
    actionRedirectPayload: unknown;
};

export type ReduxContactsPropTypes = {
    loading?: boolean;
    paymentAccounts?: WebBankAccount[];
}

export type ReduxAuthPropTypes = {
    checkedLogin: boolean;
    loggingIn: boolean;
    loggingOut: boolean;
    error?: string[];
    loginError?: LoginErrorType;
    pinLoginType?: InsufficientAuthenticationType,
    userData?: UserDetailsPropTypes;
    currentCompanyMarkupIndex?: number;
    expandedView: boolean;
    expandedModalView: boolean;
    modalView: boolean;
    locationBeforeLogin?: Location;
    redirectedAfter401: boolean;
};

export type ReduxMultiPaymentPropTypes = {
    activeOrders: BookingListItemPropTypes[];
    multipleOrders?: boolean;
    agreeTerms: boolean;
    bookingIds?: number[];
    allowedInPaymentPages: boolean;
    error?: string[];
    route?: string;
};

type LastBookingFlowLocations = {
    lastSearchResultsLocation: undefined,
    lastSearchResultsListingLocation: undefined,
    lastBookingLocation: {
        pathname: string,
        hash: string,
        search: string
    }
};

export type ReduxRouterLastLocationsPropTypes = {
    hotels: LastBookingFlowLocations,
    transfers: LastBookingFlowLocations,
    myBookings: {
        lastSearchResultsLocation: {
            pathname: string,
            hash: string,
            search: string
        },
        lastBookingLocation?: string
    },
    myCompany: {
        lastMyCompanyLocation: {
            pathname: string
            hash: string,
            search: string
        }
    }
};

export type ReduxRouterPropTypes = {
    noGoBackPaths: string[]
    specialPaths: { baseKey?: string, lastLocationKey?: string, withQueryParams?: boolean },
    disabledNav: boolean,
    lastLocations: ReduxRouterLastLocationsPropTypes;
};

export type ReduxCurrencyPropTypes = {
    currencies: {
        currencies: Currency[]
    }
    currentCurrency: Currency
    error: string;
};

export type ReduxLocalePropTypes = {
    locales: LocaleResult,
    currentLocale: string;
    error: string;
};

export type ReduxBookingDetailsPropTypes = {
    data?: WebTransferBookingDetails | WebHotelBookingDetails
    //multiBookingData: null,
    multiBooking: boolean,
    bookingReferences?: string[];
    requestingBookingDetails: boolean,
    loadedData: boolean,
    error?: string,
    settingMemo: boolean,
    bookingDetailsParams?: {
        locale: string,
        currency: Currency
    },
    bookingId?: number;
    paymentOrderId?: number;
    requestingCancelBooking: boolean;
};

export type ReduxCompanyPropTypes = {
    requestingCompanyDetails: boolean,
    error?: string;
    data: CompanyDetailsResponse
};

export type ReduxPinnedOffersPropTypes = {
    pinnedOffers: PinnedOffer[],
    pinnedHotels: HotelOfferPropTypes[],
    slideDuration: number,
    slide: boolean,
    error?: string
};

export type ReduxMapPropTypes = {
    zoom: number;
    center: google.maps.LatLng;
    precalculatedZoom?: number;
    precalculatedCenter?: google.maps.LatLng;
    mapTypeId: string;
    fullWidthMap: boolean;
    drawingMode?: google.maps.drawing.OverlayType;
    mobileDrawing: boolean;
    polygon?: google.maps.Polygon;
    circle?: google.maps.Circle;
    showMapFilterBox: boolean;
    mapCanBeLoaded: boolean;
    places: GooglePlace[];
};

export type ReduxWebSocketPropTypes = {
    notificationsData?: Notifications,
    sendMessage?: { topic: string, msg?: any }
};

export type ReduxZipkinPropTypes = {
    tracer: zipkin.Tracer;
};

export type ReduxPricingPropTypes = {
    loading: boolean;
    lastSalePrice?: number;
    transactionFee?: number;
    displayTransactionFee?: number;

    rememberedTransactionFee?: number;
};

export type RootReducer = {
    hotelBooking: ReduxHotelBookingPropTypes;
    hotelSearch: ReduxHotelSearchPropTypes;
    hotelSearchResults: ReduxHotelSearchResultsPropTypes;
    transferSearch: ReduxTransferSearch;
    transferSearchResults: ReduxTransferSearchResults;
    transferBooking: ReduxTransferBookingPropTypes;
    bookingDetails: ReduxBookingDetailsPropTypes;
    company: ReduxCompanyPropTypes;
    myBookings: ReduxMyBookingsPropTypes;
    map: ReduxMapPropTypes;
    auth: ReduxAuthPropTypes;
    destinations: ReduxDestinationsPropTypes;
    nationality: ReduxNationalityPropTypes;
    locale: ReduxLocalePropTypes;
    currency: ReduxCurrencyPropTypes
    router: ReduxRouterPropTypes;
    pinnedOffers: ReduxPinnedOffersPropTypes
    overpayment: ReduxOverpaymentPropTypes;
    multiPayment: ReduxMultiPaymentPropTypes;
    webSocket: ReduxWebSocketPropTypes;
};
