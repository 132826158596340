import IAppActions from "redux/store/store.actions"
import ContactsTypes from "redux/constants/contacts.constants"
import { WebBankAccount } from "proptypes/PropTypeObjects"
import { CommonActionReturnType } from "redux/store/store.init"
import { globalAxiosController } from "api/axios/axiosInstance"
import getPaymentAccounts from "api/contactsAPI"

export const reqPaymentAccounts = (): IAppActions => ({
  type: ContactsTypes.REQ_PAYMENT_ACCOUNTS
});

export const setPaymentAccounts = (accounts: WebBankAccount[]): IAppActions => ({
  type: ContactsTypes.SET_PAYMENT_ACCOUNTS,
  resp: accounts
});

export const errPaymentAccounts = (): IAppActions => ({
  type: ContactsTypes.ERR_PAYMENT_ACCOUNTS
});

export const fetchPaymentAccounts = (): CommonActionReturnType => {
  return (dispatch, getState) => {
    const {
      loading
    } = getState().contacts

    if (loading !== undefined) {
      return
    }

    dispatch(reqPaymentAccounts())
    globalAxiosController.addRequest(getPaymentAccounts()).then((resp) => {
      if (resp && resp.paymentAccounts) {
        dispatch(setPaymentAccounts(resp.paymentAccounts))
      }
    }).catch(() => {
      dispatch(errPaymentAccounts())
    })
  }
}