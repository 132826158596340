import {ConstrainedAxiosRequestConfig} from "api/axios/axiosController";
import {PaymentAccountsResult} from "proptypes/PropTypeObjects";

export default function getPaymentAccounts(): ConstrainedAxiosRequestConfig<PaymentAccountsResult> {
    return {
        method: "GET",
        url: "/app/payment-account/",
        headers: {
            Accept: "application/json"
        },
        maxNumRequests: 1
    }
}