import React, {ChangeEvent, FormEvent, ReactElement, useCallback, useEffect, useMemo, useState} from "react"
import cx from "classnames";
import UserButton from "components/base/UserButton";
import Roomate from "components/common/Roomate";
import {ReactComponent as StarIcon} from "assets/icons/star.svg";
import _, {DebouncedFunc} from "lodash"
import {useTranslation} from "react-i18next";
import {ReactComponent as TriangleIcon} from "assets/icons/arrow.svg";
import styles from "./SearchBox.module.scss";
import Slider from "../../Input/Slider/Slider";
import {
    CompanyMarkupsPropTypes,
    CriteriaRoomPropTypes,
    DestinationDestinationPropTypes,
    DestinationsResultPropTypes,
    HotelSearchResultsFiltersPropTypes,
    NationalityResultPropTypes,
    Option,
    PriceRange,
    RadiusDestinationPropTypes,
    RadiusPropTypes,
    RecentDestinationPropTypes,
    RecentSearchPropTypes,
    ReduxModalType,
    ReviewRatingFilterPropTypes
} from "proptypes/PropTypeObjects";
import Input from "../../Input/TextInput/Input";
import CustomCheckBox from "../../Input/CustomCheckBox";
import {UserButtonVariant} from "../../UserButton/UserButton";
import CustomerReviewRating from "./CustomerReviewRating";
import scrollToFormError from "utils/scrollToFormError";
import getCurrencySymbol from "@hotelston_web_frontend_components/util/currency/getCurrencySymbol";
import useLocaleDateTimeFormat, {
    LocaleDateFormatTypeEnum
} from "../../../utils/Internationalization/useLocaleDateTimeFormat";
import BasicDropdown from "../../Input/BasicDropdown/BasicDropdown";
import {useAppSelector} from "redux/hooks";
import {createNewTrace} from "redux/reducers/zipkin.reducer";
import store from "redux/store/store.init";
import DateRangePicker from "components/base/Input/DatePicker/DateRangePicker/DateRangePicker";
import createLuxonDate from "utils/date/createLuxonDate"
import {DateTime} from "luxon"
import StandaloneDropdownWithSearch, {
    DropdownWithSearchOption
} from "components/base/Input/DropdownWithSearch/StandaloneDropdownWithSearch";
import destinationOptionSorter from "utils/hotel/destinationOptionSort";
import ProviderOfferFilter from "./ProviderOfferFilter";
import Currency from "@hotelston_web_frontend_parent/src/constants/currency";

type Props = {
    data: {
        stateFilters: HotelSearchResultsFiltersPropTypes;
        boardTypes: { value: string | number; label: string; }[];
        features: { value: number; label: string; }[];
        nationalities?: NationalityResultPropTypes;
        destinations?: DestinationsResultPropTypes;
        stateCheckIn?: string;
        stateCheckOut?: string;
        checkIn?: string;
        checkOut?: string;
        hotelId?: number;
        cityId?: number;
        airportId?: number;
        radius?: RadiusPropTypes;
        rooms: {
            adults: number;
            children: number[];
        }[];
        currency: Currency;
        clientNationality?: string;
        destinationInput?: string;
        nationalityInput?: string;
        maxPrice?: number;
        maxAllowed?: number;
        propertyTypes: string[];
        priceFilter: PriceRange | undefined;
    };
    clearFilters: (...args: any[]) => any;
    handlePriceFilterChange: (priceRange: PriceRange) => void;
    handleSpecialOffersFilter: (checked: boolean) => void;
    handleMemberOnlyFilter: (checked: boolean) => void;
    handleOnlyNonRefundableFilter: (checked: boolean) => void;
    handleOnlyRefundableFilter: (checked: boolean) => void;
    handleOnlyGoodCXLFilter: (checked: boolean) => void;
    handleFeatures: (value: number, isActive: boolean) => void;
    handleHotelCategory: (value: number | string, isActive: boolean) => void;
    handlePropertyType: (value: string, isActive: boolean) => void;
    handleReviewRating: (filter: ReviewRatingFilterPropTypes) => void;
    handleAddRoom: (...args: any[]) => any;
    handleRemoveRoom: (...args: any[]) => any;
    handleChildrenChange: (...args: any[]) => any;
    handleAdultsChange: (...args: any[]) => any;
    handleFilterInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleDateChange: (...args: any[]) => any;
    handleBoardType: (value: number | string, isActive: boolean) => void;
    handleDestinations: (...args: any[]) => any;
    handleInputChange: (...args: any[]) => any;
    handleNatChange: (clientNationality: string, nationalityInput: string) => void;
    handleSubmitFilterUpdate: (e?: ChangeEvent<HTMLInputElement> | FormEvent<HTMLFormElement>) => void;
    handleSubmitSearch: (...args: any[]) => any;
    getDestinations: DebouncedFunc<(value?: string | undefined) => Promise<void>>;
    currentDestination?: DestinationDestinationPropTypes | RecentDestinationPropTypes;
    markups?: CompanyMarkupsPropTypes[],
    currentMarkup?: number,
    changeCurrentCompanyMarkupIndex: (currentCompanyMarkupIndex?: (number | undefined)) => void,
};

const TEXT_INPUT_DEBOUNCE_DELAY = 1000;

const SearchBox = (props: Props): ReactElement => {
    const {
        data: {
            destinations: {
                destinations,
                hotels,
                airports,
                radius,
                recents,
                destinationsCount = 0
            } = {},
            nationalities: {
                nationalities = []
            } = {},
            clientNationality,
            nationalityInput,
            destinationInput,
            boardTypes,
            features,
            rooms,
            stateFilters,
            priceFilter,
            maxPrice = 1000,
            maxAllowed = 1000,
            stateCheckIn,
            stateCheckOut,
            currency,
            propertyTypes
        },
        clearFilters,
        handleSpecialOffersFilter,
        handleMemberOnlyFilter,
        handleOnlyNonRefundableFilter,
        handleOnlyRefundableFilter,
        handleOnlyGoodCXLFilter,
        handleFeatures,
        handleHotelCategory,
        handlePropertyType,
        handleAddRoom,
        handleRemoveRoom,
        handleChildrenChange,
        handleAdultsChange,
        handleFilterInputChange,
        handleBoardType,
        handleDestinations,
        handleInputChange,
        handleNatChange,
        getDestinations,
        currentDestination,
        markups,
        currentMarkup,
        handleSubmitSearch,
        changeCurrentCompanyMarkupIndex,
        handleReviewRating,
        handlePriceFilterChange,
        handleDateChange
    } = props;

    const {t} = useTranslation();
    const dateFormat = useLocaleDateTimeFormat(LocaleDateFormatTypeEnum.DATE);

    const [searchFormExpanded, setSearchFormExpanded] = useState<boolean>(false);
    const [innerMinInputValue, setInnerMinInputValue] = useState<number>(stateFilters.priceFilter?.min || 0);
    const [innerMaxInputValue, setInnerMaxInputValue] = useState<number>(stateFilters.priceFilter?.max || maxAllowed);

    const filterStatistics = useAppSelector(
        (state) => state.hotelSearchResults.statistics
    );
    const modal = useAppSelector(
        (state) => state.modals.currentActiveModal
    );
    const ownUser = useAppSelector(
        (state) => state.auth.userData?.companyUser?.ownUser || false
    );

    // const minValue = useMemo(() => {
    //     if (priceFilter?.min || stateFilters.priceFilter?.min || 0 >= 0) {
    //         return priceFilter?.min || stateFilters.priceFilter?.min || innerMinInputValue;
    //     } else {
    //         return 0;
    //     }
    // }, [priceFilter?.min, stateFilters.priceFilter?.min, innerMinInputValue]);

    const maxValue = useMemo(() => {
        if (priceFilter?.max && (!maxAllowed || priceFilter?.max <= maxAllowed)) {
            return priceFilter.max;
        } else if (!maxAllowed || maxAllowed > maxPrice) {
            return maxPrice;
        } else {
            return maxAllowed;
        }
    }, [maxAllowed, maxPrice, priceFilter?.max]);

    useEffect(() => {
        setInnerMinInputValue(stateFilters.priceFilter?.min || 0)
    }, [priceFilter?.min, stateFilters.priceFilter?.min, innerMinInputValue]);

    useEffect(() => {
        setInnerMaxInputValue(stateFilters.priceFilter?.max || maxAllowed)
    }, [priceFilter?.max, stateFilters.priceFilter?.max, innerMaxInputValue, maxAllowed]);

    const step = useMemo(() => {
        if (maxPrice > (maxAllowed || maxValue)) {
            return Math.ceil((maxAllowed || maxValue) / 20);
        } else {
            return Math.ceil(maxPrice / 20);
        }
    }, [maxAllowed, maxPrice, maxValue]);

    const destinationOptions = useMemo(() => {
        const options: DropdownWithSearchOption<DestinationDestinationPropTypes | RadiusDestinationPropTypes | RecentSearchPropTypes>[] = [];

        if (destinations) {
            destinationOptionSorter(destinations).forEach((item) => {
                options.push(new DropdownWithSearchOption(
                    item.name,
                    item,
                    [item.stateName, item.countryName].filter((t) => !!t).join(", "),
                    "destinations"
                ));
            });
        }

        if (hotels) {
            destinationOptionSorter(hotels).forEach((item) => {
                options.push(new DropdownWithSearchOption(
                    item.name,
                    item,
                    [item.cityName, item.stateName, item.countryName].filter((t) => !!t).join(", "),
                    "hotels"
                ));
            })
        }

        if (airports) {
            destinationOptionSorter(airports).forEach((item) => {
                options.push(new DropdownWithSearchOption(
                    item.name,
                    item,
                    item.countryName,
                    "airports"
                ));
            })
        }

        if (radius) {
            radius.forEach((item) => {
                options.push(new DropdownWithSearchOption(
                    item.name,
                    item,
                    item.countryName,
                    "radius"
                ));
            })
        }

        if (recents) {
            recents.forEach((item) => {
                options.push(new DropdownWithSearchOption(
                    item.destination ? `${item.destination.cityName || item.destination.hotelName || item.destination.airportName || item.destination.radiusName}, ${item.destination.countryName || ""}` : "",
                    item,
                    undefined,
                    "recents"
                ));
            })
        }

        return options;
    }, [airports, destinations, hotels, radius, recents]);

    const companyMarkupOptions = markups && markups.map(({name}) => ({
        value: name,
        label: name
    })) as Option<string, string>[];
    const companyMarkupValue = companyMarkupOptions && companyMarkupOptions[currentMarkup || 0] && companyMarkupOptions[currentMarkup || 0].value;

    const destinationGroups = useMemo(() => [
        {
            order: 1,
            name: "destinations",
            label: t("hsb_sb_destinations"),
            endLabel: destinationsCount > 4 ? t("crs_dws_more") : undefined
        },
        {order: 2, name: "hotels", label: t("hsb_sb_hotels")},
        {order: 3, name: "airports", label: t("hsb_sb_airports")},
        {order: 4, name: "radius", label: t("hsb_sb_radius")},
        {order: 5, name: "recents", label: t("hsb_sb_recents")}
    ], [destinationsCount, t]);

    const nationalitiesFormed = useMemo(() => nationalities.map((nat) => new DropdownWithSearchOption(
        nat.name, nat.iso
    )), [nationalities]);

    const starRatings = useMemo(() => {
        const spanClasses = {
            allStar: cx(styles.Item, stateFilters.hotelCategoryFilter?.length === 0 && styles.ItemActive),
            fiveStar: cx(styles.Star, stateFilters.hotelCategoryFilter?.includes(5) && styles.StarActive),
            fourStar: cx(styles.Star, stateFilters.hotelCategoryFilter?.includes(4) && styles.StarActive),
            threeStar: cx(styles.Star, stateFilters.hotelCategoryFilter?.includes(3) && styles.StarActive),
            twoStar: cx(styles.Star, stateFilters.hotelCategoryFilter?.includes(2) && styles.StarActive),
            oneStar: cx(styles.Star, stateFilters.hotelCategoryFilter?.includes(1) && styles.StarActive),
            unrated: cx(styles.Item, stateFilters.hotelCategoryFilter?.includes(0) && styles.ItemActive)
        };

        return [
            {
                value: "All",
                label: t("hsb_sb_select_all")
            },
            {
                value: 5,
                label: (
                    <>
                        <StarIcon className={spanClasses.fiveStar}/>
                        <StarIcon className={spanClasses.fiveStar}/>
                        <StarIcon className={spanClasses.fiveStar}/>
                        <StarIcon className={spanClasses.fiveStar}/>
                        <StarIcon className={spanClasses.fiveStar}/>
                        <span> ({filterStatistics?.starRatingCounts[5] || 0})</span>
                    </>
                )
            },
            {
                value: 4,
                label: (
                    <>
                        <StarIcon className={spanClasses.fourStar}/>
                        <StarIcon className={spanClasses.fourStar}/>
                        <StarIcon className={spanClasses.fourStar}/>
                        <StarIcon className={spanClasses.fourStar}/>
                        <span> ({filterStatistics?.starRatingCounts[4] || 0})</span>
                    </>
                )
            },
            {
                value: 3,
                label: (
                    <>
                        <StarIcon className={spanClasses.threeStar}/>
                        <StarIcon className={spanClasses.threeStar}/>
                        <StarIcon className={spanClasses.threeStar}/>
                        <span> ({filterStatistics?.starRatingCounts[3] || 0})</span>
                    </>
                )
            },
            {
                value: 2,
                label: (
                    <>
                        <StarIcon className={spanClasses.twoStar}/>
                        <StarIcon className={spanClasses.twoStar}/>
                        <span> ({filterStatistics?.starRatingCounts[2] || 0})</span>
                    </>
                )
            },
            {
                value: 1,
                label: (
                    <>
                        <StarIcon className={spanClasses.oneStar}/>
                        <span> ({filterStatistics?.starRatingCounts[1] || 0})</span>
                    </>
                )
            },
            {
                value: 0,
                label: (
                    <>
                        <span className={spanClasses.unrated}>{t("hsb_sb_unrated")}</span>
                        <span> ({filterStatistics?.starRatingCounts[0] || 0})</span>
                    </>
                )
            }
        ];
    }, [filterStatistics, stateFilters.hotelCategoryFilter, t]);

    const handleValidationAndSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
        if (!e) {
            return;
        }
        const form = e.target as HTMLFormElement;
        e.preventDefault();
        const valid = form.checkValidity();

        if (!valid) {
            scrollToFormError(form);
        }

        if (valid) {
            const {tracer} = store.getState().zipkin;
            createNewTrace(tracer);

            handleSubmitSearch();
        }
    }, [handleSubmitSearch]);

    const changeCompanyMarkup = useCallback((val?: string | number, label?: string | number, i?: number) => {
        changeCurrentCompanyMarkupIndex(i);
    }, [changeCurrentCompanyMarkupIndex]);

    const handleDatesChangeCallback = useCallback((startDate: DateTime, endDate: DateTime) => {
        const start = createLuxonDate(startDate).toFormat("yyyy-MM-dd");
        let end = endDate ? createLuxonDate(endDate).toFormat("yyyy-MM-dd") : createLuxonDate(startDate).toFormat("yyyy-MM-dd");

        if (createLuxonDate(end).valueOf() <= createLuxonDate(start).valueOf()) {
            end = createLuxonDate(start).plus({day: 1}).toFormat("yyyy-MM-dd");
        }

        handleDateChange(start, end);
    }, [handleDateChange]);

    const handleSubmitFilterUpdate = useCallback(_.debounce(() => props.handleSubmitFilterUpdate(), TEXT_INPUT_DEBOUNCE_DELAY), []);

    const handlePriceMin = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const newValue = +e.target.value
        handlePriceFilterChange({
            min: newValue < maxValue ? newValue : stateFilters.priceFilter?.max,
            max: stateFilters.priceFilter?.max
        });
        handleSubmitFilterUpdate();
    }, [handlePriceFilterChange, handleSubmitFilterUpdate, stateFilters.priceFilter?.max, maxValue]);

    const handlePriceMax = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const newValue = +e.target.value
        handlePriceFilterChange({
            min: stateFilters.priceFilter?.min,
            max: newValue < maxAllowed ? newValue : maxAllowed
        });
        handleSubmitFilterUpdate();
    }, [handlePriceFilterChange, handleSubmitFilterUpdate, stateFilters.priceFilter?.min, maxAllowed]);

    const handlePriceMinMax = useCallback(({
        min,
        max
    }: PriceRange) => {
        handlePriceFilterChange({
            min,
            max
        });

        handleSubmitFilterUpdate();
    }, [handlePriceFilterChange, handleSubmitFilterUpdate]);

    const handleReviewRatingCallback = useCallback((f: ReviewRatingFilterPropTypes) => {
        handleReviewRating(f);
        handleSubmitFilterUpdate();
    }, [handleReviewRating, handleSubmitFilterUpdate]);

    return (
        <div className={cx(styles.LefterHotelSearchBox, modal === ReduxModalType.FILTERS && styles.ModalActive)}>
            <form
                id="hotel-side-search-form"
                autoComplete="off"
                noValidate
                onSubmit={handleValidationAndSubmit}
                className={cx(styles.SearchForm, searchFormExpanded && styles.SearchFormExpanded)}
            >
                <h3 className={styles.TitleText} onClick={() => setSearchFormExpanded(!searchFormExpanded)}>
                    {t("hsb_sb_search")}

                    <TriangleIcon/>
                </h3>

                <div className={styles.SearchSummary}>
                    <dl>
                        <dt>{t("hsb_sb_summary_destination")}</dt>
                        <dd>{(currentDestination && currentDestination.cityName && currentDestination.countryName && [currentDestination?.cityName, currentDestination?.countryName].join(", ")) || destinationInput}</dd>

                        <dt>{t("hsb_sb_summary_check_in")}</dt>
                        <dd>{createLuxonDate(stateCheckIn).toFormat(dateFormat)}</dd>

                        <dt>{t("hsb_sb_summary_check_out")}</dt>
                        <dd>{createLuxonDate(stateCheckOut).toFormat(dateFormat)}
                        </dd>

                        <dt>{t("hsb_sb_summary_adults")}</dt>
                        <dd>{rooms.reduce((prevVal: number, next: CriteriaRoomPropTypes) => prevVal + next.adults, 0)}</dd>

                        <dt>{t("hsb_sb_summary_children")}</dt>
                        <dd>{rooms.reduce((prevVal: number, next: CriteriaRoomPropTypes) => prevVal + next.children.length, 0)}</dd>

                        <dt>{t("hsb_sb_summary_rooms")}</dt>
                        <dd>{rooms.length}</dd>

                        <dt>{t("hsb_sb_summary_nationality")}</dt>
                        <dd>{nationalityInput}</dd>
                    </dl>

                    <div className={styles.MarkupSelect}>
                        <label>{t("hsb_sb_summary_markup")}</label>

                        <BasicDropdown
                            required={true}
                            hideErrors={true}
                            noBorders={true}
                            onDropdownChange={changeCompanyMarkup}
                            dropDownOptions={companyMarkupOptions || []}
                            dropDownValue={companyMarkupValue}
                            className="BasicDropdown"
                        />
                    </div>

                    <UserButton
                        text={t("hsb_sb_summary_explanation")}
                        variant={UserButtonVariant.PRIMARY}
                        buttonProps={{
                            type: "button",
                            onClick: () => setSearchFormExpanded(!searchFormExpanded)
                        }}
                    />

                    {/*<span onClick={() => this.setState({ searchFormExpanded: !searchFormExpanded })}>{t("hsb_sb_summary_explanation")}</span>*/}
                </div>

                <div className={styles.SearchFormInputContainer}>
                    <div className={cx(styles.CriteriaItem, styles.DestinationDropdown)}>
                        <StandaloneDropdownWithSearch
                            smallFont
                            filterEmptyMatch={false}
                            customErrorMessage={t("util_vf_error_no_destination")}//util_vf_error_no_destination //util_vf_error_not_valid_destination
                            inputProps={{
                                required: true,
                                name: "destinationInput",
                                value: destinationInput,
                                placeholder: t("hsb_sb_destination"),
                                onChange: (e) => {
                                    handleInputChange(e);
                                },
                                onFocus: (e) => {
                                    getDestinations(e.target.value);
                                }
                            }}
                            onSearchChange={(e) => getDestinations(e)}
                            className={styles.DestinationBox}
                            dropdownClassName={styles.DestinationBoxOptions}
                            groups={destinationGroups}
                            onValueChange={(opt) => {
                                handleDestinations(opt.value, opt.fullLabel(), opt.group || "");
                            }}
                            options={destinationOptions}
                            currentValue={currentDestination as DestinationDestinationPropTypes | RecentSearchPropTypes | RadiusDestinationPropTypes}
                        />
                    </div>

                    <div className={styles.CriteriaItem}>
                        <DateRangePicker
                            vertical={true}
                            from={createLuxonDate(stateCheckIn)}
                            to={createLuxonDate(stateCheckOut)}
                            handleDateChange={handleDatesChangeCallback}
                            className={styles.DateRangePicker}
                            fromLabel={t("h_s_sb_checkin")}
                            toLabel={t("h_s_sb_checkout")}
                            nightsLabel={t("h_s_sb_night_other")}
                            validFrom={createLuxonDate().minus({day: 1})}
                        />
                    </div>

                    <div className={styles.CriteriaItem}>
                        {rooms?.map((val, i) => (
                            <Roomate
                                key={i}
                                count={rooms.length}
                                index={i}
                                data={val}
                                sidebar
                                handleAdultsChange={handleAdultsChange}
                                handleChildrenChange={handleChildrenChange}
                                handleRemoveRoom={handleRemoveRoom}
                            />
                        ))}
                    </div>

                    <div className={styles.CriteriaItem}>
                        <span
                            className={cx(styles.AddRoom, (rooms && rooms.length >= 8) ? styles.AddRoomDisabled : "")}
                            onClick={handleAddRoom}
                        >
                            + {t("hsb_sb_add_room")}
                        </span>
                    </div>

                    <StandaloneDropdownWithSearch
                        smallFont
                        filterEmptyMatch={true}
                        className={cx(styles.CriteriaItem, styles.NationalityBox)}
                        inputProps={{
                            name: "nationalityInput",
                            value: nationalityInput,
                            placeholder: t("hsb_sb_client_nationality"),
                            required: true,
                            onChange: (e) => {
                                handleInputChange(e);
                            }
                        }}
                        onValueChange={(value: DropdownWithSearchOption<string>) => {
                            handleNatChange(value.value, value.label);
                        }}
                        currentValue={clientNationality}
                        options={nationalitiesFormed}
                    />

                    <UserButton
                        searchButton
                        text={t("hsb_sb_search_hotels_button_text")}
                        variant={UserButtonVariant.PRIMARY}
                        className={styles.CriteriaItem}
                        buttonProps={{
                            type: "submit",
                            style: {
                                height: "40px"
                            }
                        }}
                    />
                </div>
            </form>

            <div className={styles.Divider}/>

            <form id="hotel-side-filter-form" autoComplete="off" onSubmit={handleSubmitFilterUpdate}>
                <h3 className={styles.TitleText}>{t("hsb_sb_filter")}</h3>

                {ownUser &&
                    <div className={styles.CriteriaItem}>
                        <ProviderOfferFilter
                            handleSubmitFilterUpdate={handleSubmitFilterUpdate}
                            handleFilterInputChange={handleFilterInputChange}
                        />
                    </div>}

                <div className={styles.CriteriaItem}>
                    <Input
                        variants={{labelPosition: "outlined"}}
                        fullHeight={false}
                        inputProps={{
                            onChange: (e) => {
                                handleFilterInputChange(e);
                                handleSubmitFilterUpdate();
                            },
                            type: "text",
                            name: "hotelNameFilter",
                            value: stateFilters.hotelNameFilter,
                            placeholder: t("hsb_sb_hotel_name")
                        }}
                        helperText={t("hsb_sb_hotel_name_explanation")}
                    />
                </div>

                <div className={styles.CriteriaItem}>
                    <h4 className={styles.Title}>{t("hsb_sb_category_of_hotels")}</h4>
                    {starRatings.map((value) => (
                        <CustomCheckBox
                            key={value.value}
                            label={value.label}
                            inputProps={{
                                checked: stateFilters.hotelCategoryFilter.includes(value.value) || (stateFilters.hotelCategoryFilter.length === 0 && value.value === "All"),

                                onChange: (e) => {
                                    handleHotelCategory(value.value, e.target.checked);
                                    handleSubmitFilterUpdate();
                                }
                            }}
                        />
                    ))}
                </div>

                <div className={styles.CriteriaItem}>
                    <h4 className={styles.Title}>{t("hsb_sb_price")}</h4>
                    <div className={styles.RangeBox}>
                        <div className={styles.ValueMin}>
                            <div className={styles.RangeValueLabel}>{t("hsb_sb_min")}</div>
                            <div className={styles.RangeValue}>
                                <span className="currency">
                                    {getCurrencySymbol(currency)}
                                </span>
                                <input
                                    type="number"
                                    onChange={handlePriceMin}
                                    value={innerMinInputValue}
                                />
                            </div>
                        </div>

                        <div className={styles.ValueMax}>
                            <div className={styles.RangeValueLabel}>{t("hsb_sb_max")}</div>
                            <div className={styles.RangeValue}>
                                <span className="currency">
                                    {getCurrencySymbol(currency)}
                                </span>
                                <input
                                    type="number"
                                    onChange={handlePriceMax}
                                    value={innerMaxInputValue}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {maxAllowed && maxValue && (
                    <div className={styles.CriteriaItem}>
                        <Slider
                            min={0}
                            max={maxAllowed}
                            maxShown={maxAllowed}
                            minValue={innerMinInputValue}
                            maxValue={innerMaxInputValue}
                            step={step}
                            onChange={handlePriceMinMax}
                            debounceDelay={TEXT_INPUT_DEBOUNCE_DELAY}
                        />
                    </div>
                )}

                <div className={styles.CriteriaItem}>
                    <h4 className={styles.Title}>{t("hsb_sb_board_type")}</h4>
                    {boardTypes.map((boardType, i) => (
                        <CustomCheckBox
                            key={boardType.value}
                            label={boardType.label + (i !== 0 ? ` (${filterStatistics?.boardTypeCounts?.get(Number(boardType.value)) || 0})` : "")}
                            inputProps={{
                                checked: stateFilters.boardTypeFilter.includes(boardType.value) || (stateFilters.boardTypeFilter.length === 0 && boardType.value === "All"),
                                onChange: (e) => {
                                    handleBoardType(boardType.value, e.target.checked);
                                    handleSubmitFilterUpdate();
                                }
                            }}
                        />
                    ))}
                </div>

                <div className={styles.CriteriaItem}>
                    <Input
                        variants={{labelPosition: "outlined"}}
                        fullHeight={false}
                        inputProps={{
                            onChange: (e) => {
                                handleFilterInputChange(e);
                                handleSubmitFilterUpdate();
                            },
                            type: "text",
                            name: "roomTypeFilter",
                            value: stateFilters.roomTypeFilter,
                            placeholder: t("hsb_sb_room_type_with_example"),
                            className: "room-type"
                        }}
                    />
                </div>

                <div className={styles.CriteriaItem}>
                    <h4 className={styles.Title}>{t("hsb_sb_special_offer")}</h4>
                    <CustomCheckBox
                        label={t("hsb_sb_show_specialOffers_only")}
                        inputProps={{
                            checked: stateFilters.specialOffersFilter,
                            onChange: (e) => {
                                handleSpecialOffersFilter(e.target.checked);
                                handleSubmitFilterUpdate();
                            },
                            name: "specialOffers"
                        }}
                    />
                    <CustomCheckBox
                        inputProps={{
                            name: "memberOnly",
                            checked: stateFilters.memberOnlyFilter,
                            onChange: (e) => {
                                handleMemberOnlyFilter(e.target.checked);
                                handleSubmitFilterUpdate();
                            }
                        }}
                        label={t("hsb_sb_show_memberOnly_only")}
                    />
                </div>

                <div className={styles.CriteriaItem}>
                    <h4 className={styles.Title}>{t("hsb_sb_refundable")}</h4>
                    <CustomCheckBox
                        inputProps={{
                            name: "onlyGoodCXL",
                            checked: stateFilters.onlyGoodCXLFilter,
                            onChange: (e) => {
                                handleOnlyGoodCXLFilter(e.target.checked);
                                handleSubmitFilterUpdate();
                            }
                        }}
                        label={t("hsb_sb_show_goodCXL_only")}
                    />
                    <CustomCheckBox
                        inputProps={{
                            name: "onlyRefundable",
                            checked: stateFilters.onlyRefundableFilter,
                            onChange: (e) => {
                                handleOnlyRefundableFilter(e.target.checked);
                                handleSubmitFilterUpdate();
                            }
                        }}
                        label={t("hsb_sb_show_refundable_only")}
                    />
                    <CustomCheckBox
                        inputProps={{
                            name: "onlyNonRefundable",
                            checked: stateFilters.onlyNonRefundableFilter,
                            onChange: (e) => {
                                handleOnlyNonRefundableFilter(e.target.checked);
                                handleSubmitFilterUpdate();
                            }

                        }}
                        label={t("hsb_sb_show_nonRefundable_only")}
                    />
                </div>

                <CustomerReviewRating
                    filters={stateFilters}
                    handleReviewRating={handleReviewRatingCallback}
                />

                <div className={styles.CriteriaItem}>
                    <h4 className={styles.Title}>{t("hsb_sb_property_type")}</h4>
                    {!_.isEmpty(propertyTypes) && propertyTypes.map((value) => (
                        <CustomCheckBox
                            key={_.uniqueId(`${value}_`)}
                            label={value + ` (${filterStatistics?.accomodationTypeCounts?.get(value) || 0})`}
                            inputProps={{
                                checked: stateFilters.propertyTypeFilter.includes(value),
                                onChange: (e) => {
                                    handlePropertyType(value, e.target.checked);
                                    handleSubmitFilterUpdate();
                                }
                            }}
                        />
                    ))}
                </div>

                <div className={styles.CriteriaItem}>
                    <h4 className={styles.Title}>{t("hsb_sb_features")}</h4>
                    {features.map((feature) => (
                        <CustomCheckBox
                            key={feature.value}
                            label={feature.label}
                            inputProps={{
                                checked: stateFilters.featuresFilter.includes(feature.value),
                                onChange: (e) => {
                                    handleFeatures(feature.value, e.target.checked);
                                    handleSubmitFilterUpdate();
                                }
                            }}
                        />
                    ))}
                </div>

                <div className={styles.FilterActions}>
                    <UserButton
                        text={t("hsb_sb_clear_button")}
                        variant={UserButtonVariant.SECONDARY}
                        buttonProps={{
                            type: "button",
                            onClick: clearFilters
                        }}
                    />

                    {/*<UserButton*/}
                    {/*    text={t("hsb_sb_filter")}*/}
                    {/*    variant={UserButtonVariant.PRIMARY}*/}
                    {/*    buttonProps={{*/}
                    {/*        type: "submit",*/}
                    {/*        onClick: handleSubmitFilterUpdate*/}
                    {/*    }}*/}
                    {/*/>*/}
                </div>
            </form>
        </div>
    );
};

export default SearchBox;
