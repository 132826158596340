import React, {ReactElement, useEffect, useRef} from "react";
import InfoBox from "components/common/InfoBox";
import Pricing from "components/common/Pricing";
import {useTranslation} from "react-i18next";
import Input from "components/base/Input";
import titles from "assets/json/titles.json";
import BasicDropdown from "components/base/Input/BasicDropdown/BasicDropdown";
import {EMAIL_PATTERN, PHONE_PATTERN, validateName} from "utils/validateForm";
import SmsLanguage from "./SmsLanguage";
import styles from "./BookingForm.module.scss";
import ConfirmOrder from "../../../components/common/ConfirmOrder";
import {getPriceWithCurrencySymbol} from "@hotelston_web_frontend_components/util/currency/getPriceWithCurrency";
import CustomCheckBox from "../../../components/base/Input/CustomCheckBox"
import usePaymentOptions from "components/base/Payment/PaymentType/usePaymentOptions"
import {useCurrency} from "components/utils/withLocalesAndCurrencies"
import {useAppDispatch, useAppSelector} from "redux/hooks"
import {toggleAgreeTerms, toggleInvoiceRecipient} from "redux/actions/payment.actions"
import BookingInvoiceRecipient from "components/common/BookingInvoiceRecipient/BookingInvoiceRecipient";
import useDefaultFeatureEnabled from "utils/hooks/useDefaultFeatureEnabled";

type Props = {
    handleInputChange: (...args: any[]) => any;
    handleMemo: (...args: any[]) => any;
    handleContactInfo: (...args: any[]) => any;
    toggleSmsOrdered: (...args: any[]) => any;
    handleTransferBookingInfoInput: (...args: any[]) => any;
    handleLeadPassenger: (...args: any[]) => any;
    handleValidationAndSubmit: (e: React.FormEvent<HTMLFormElement> | undefined) => void;
    handleCustomInvoiceRecipient: ({target: {value, name}}: any) => void;
};

function BookingForm({
                         handleInputChange,
                         handleMemo,
                         handleContactInfo,
                         toggleSmsOrdered,
                         handleTransferBookingInfoInput,
                         handleLeadPassenger,
                         handleValidationAndSubmit,
                         handleCustomInvoiceRecipient
                     }: Props): ReactElement {
    const transferAvailabilityCriteria = useAppSelector((state) => state.transferBooking.data?.transferAvailabilityCriteria);
    const transferBookingInfo = useAppSelector((state) => state.transferBooking.data?.transferBookingInfo);
    const transferHotelNameFromSearch = useAppSelector((state) => state.transferSearch?.currentDestination?.name);
    const transferHotelAddressFromSearch = useAppSelector((state) => state.destinations?.transferDestinations?.destinations)?.filter((destination => destination.name == transferHotelNameFromSearch))[0].address;
    // TODO change to this when /destinationPoint endpoint is live
    // const transferHotelAddressFromSearch = useAppSelector((state) => state.transferSearch?.currentDestination?.address);
    const bookingInfo = useAppSelector((state) => state.transferBooking.data?.bookingInfo);
    const errors = useAppSelector((state) => state.payment.errors);
    const transferErrors = useAppSelector((state) => state.transferBooking.data?.errors);
    const trackingId = useAppSelector((state) => state.zipkin.tracer.id);
    const agreeTerms = useAppSelector((state) => state.payment.agreeTerms);

    const dispatch = useAppDispatch();
    const currency = useCurrency();

    const {arrivalTransfer, departureTransfer} = transferAvailabilityCriteria!;

    const {
        featureEnabled: newTransferSearch,
        featuresLoaded
    } = useDefaultFeatureEnabled("web2NewTransferSearch");

    useEffect(() => {
        if (!featuresLoaded || !newTransferSearch) {
            return;
        }
        handleTransferBookingInfoInput({
            target: {
                value: transferHotelNameFromSearch || "",
                name: 'specificLocationName'
            }
        });

        handleTransferBookingInfoInput({
            target: {
                value: transferHotelAddressFromSearch || "",
                name: 'specificLocationAddress'
            }
        });
    }, [featuresLoaded, newTransferSearch]);

    const {
        paymentOptions
    } = usePaymentOptions();

    const {
        contactInfo: {
            email,
            firstname,
            lastname,
            phone,
            title
        } = {},
        memo
    } = bookingInfo!;

    const infoBoxRef = useRef<HTMLDivElement | null>(null);
    const customInvoice = useAppSelector((state) => state.payment.customInvoice);
    const invoiceRecipient = useAppSelector((state) => state.payment.invoiceRecipient);

    useEffect(() => {
        if (errors || transferErrors) {
            infoBoxRef.current?.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
        }
    }, [errors, transferErrors]);

    const {t} = useTranslation();

    return (
        <div className={styles.Root}>
            <form autoComplete="on" noValidate onSubmit={(e) => handleValidationAndSubmit(e)}>
                <div className={styles.MainHeading}>
                    <h1>{t("t_b_bf_booking")}</h1>
                    <span>
                        <>
                            {t("t_b_bf_tracking_id")}: {trackingId.traceId}
                        </>
                    </span>
                </div>

                {(errors || transferErrors) && (
                    <InfoBox
                        rootRef={infoBoxRef}
                        className="mb-60"
                        title={t("t_b_bf_something_went_wrong")}
                        message={[...(errors || []), ...(transferErrors || [])].map((errorKey) => t(errorKey)).join(", ")}
                        type="warn"
                    />
                )}

                <div className={styles.LeadPassengerInputContainer}>
                    <h4 className={styles.LeadPassengerLabel}>{t("t_b_bf_lead_passenger")}</h4>

                    <BasicDropdown
                        required={true}
                        className={styles.LeadPassengerTitleDropdown}
                        dropDownOptions={titles.titles.map(({
                                                                label,
                                                                value
                                                            }) => ({
                            value,
                            label: t(label) as unknown as string
                        }))}
                        onDropdownChange={(value) => handleLeadPassenger({
                            target: {
                                value,
                                name: "title"
                            }
                        })}
                        dropDownValue={transferBookingInfo?.leadPassenger.title}
                    />

                    <Input
                        variants={{labelPosition: "outlined"}}
                        fullHeight={false}
                        validator={(e) => validateName(e)}
                        inputProps={{
                            onChange: (e) => {
                                handleLeadPassenger(e);
                            },
                            type: "text",
                            autoComplete: "given-name",
                            name: "firstname",
                            required: true,
                            value: transferBookingInfo?.leadPassenger.firstname || "",
                            placeholder: t("t_b_bf_first_name"),
                            className: styles.inputField
                        }}
                        className={styles.FirstNameInput}
                    />

                    <Input
                        variants={{labelPosition: "outlined"}}
                        fullHeight={false}
                        validator={(e) => validateName(e)}
                        inputProps={{
                            onChange: (e) => {
                                handleLeadPassenger(e);
                            },
                            type: "text",
                            autoComplete: "family-name",
                            name: "lastname",
                            required: true,
                            value: transferBookingInfo?.leadPassenger.lastname || "",
                            placeholder: t("t_b_bf_last_name"),
                            className: styles.inputField
                        }}
                        className={styles.LastNameInput}
                    />
                </div>

                <div className={styles.SpecificLocationContainer}>
                    <h4 className={styles.SpecificLocationHeading}>{t("t_b_bf_specific_location")}</h4>
                    <div className={styles.SpecificLocationNotice}>{t("t_b_bf_specific_location_notice")}</div>

                    <Input
                        variants={{labelPosition: "outlined"}}
                        fullHeight={false}
                        inputProps={{
                            onChange: (e) => {
                                handleTransferBookingInfoInput(e);
                            },
                            type: "text",
                            autoComplete: "on",
                            name: "specificLocationName",
                            required: true,
                            value: transferBookingInfo?.specificLocationName || "",
                            placeholder: t("t_b_bf_name_of_location"),
                            className: styles.inputField
                        }}
                    />

                    <Input
                        variants={{labelPosition: "outlined"}}
                        fullHeight={false}
                        inputProps={{
                            onChange: (e) => {
                                handleTransferBookingInfoInput(e);
                            },
                            type: "text",
                            autoComplete: "on",
                            name: "specificLocationAddress",
                            required: true,
                            value: transferBookingInfo?.specificLocationAddress || "",
                            placeholder: t("t_b_bf_address"),
                            className: styles.inputField
                        }}
                    />
                </div>

                {arrivalTransfer && (
                    <div className={styles.ArrivalDepartureInputGroupContainer}>
                        <h4 className={styles.ArrivalDepartureHeading}>{t("t_b_bf_arrival_flight")}</h4>

                        <Input
                            variants={{labelPosition: "outlined"}}
                            fullHeight={false}
                            inputProps={{
                                onChange: (e) => {
                                    handleTransferBookingInfoInput(e);
                                },
                                type: "text",
                                autoComplete: "on",
                                name: "arrivalAirportFrom",
                                required: true,
                                value: transferBookingInfo?.arrivalAirportFrom || "",
                                placeholder: t("t_b_bf_from_airport_code")
                            }}
                            className={styles.ArrivalDepartureInput}
                        />

                        <Input
                            variants={{labelPosition: "outlined"}}
                            fullHeight={false}
                            inputProps={{
                                onChange: (e) => {
                                    handleTransferBookingInfoInput(e);
                                },
                                type: "text",
                                autoComplete: "on",
                                name: "arrivalAirportTo",
                                required: true,
                                value: transferBookingInfo?.arrivalAirportTo || "",
                                placeholder: t("t_b_bf_to_airport_code"),
                                disabled: true
                            }}
                            className={styles.ArrivalDepartureInput}
                        />

                        <Input
                            variants={{labelPosition: "outlined"}}
                            fullHeight={false}
                            inputProps={{
                                onChange: (e) => {
                                    handleTransferBookingInfoInput(e);
                                },
                                type: "text",
                                autoComplete: "on",
                                name: "arrivalFlightNumber",
                                required: true,
                                value: transferBookingInfo?.arrivalFlightNumber || "",
                                placeholder: t("t_b_bf_flight_number")
                            }}
                            className={styles.ArrivalDepartureInput}
                        />

                        <Input
                            variants={{labelPosition: "outlined"}}
                            fullHeight={false}
                            inputProps={{
                                onChange: (e) => {
                                    handleTransferBookingInfoInput(e);
                                },
                                type: "text",
                                autoComplete: "on",
                                name: "arrivalAirlineName",
                                required: true,
                                value: transferBookingInfo?.arrivalAirlineName || "",
                                placeholder: t("t_b_bf_arrival_airline_name")
                            }}
                            className={styles.ArrivalDepartureInput}
                        />
                    </div>
                )}

                {departureTransfer && (
                    <div className={styles.ArrivalDepartureInputGroupContainer}>
                        <h4 className={styles.ArrivalDepartureHeading}>{t("t_b_bf_departure_flight")}</h4>

                        <Input
                            variants={{labelPosition: "outlined"}}
                            fullHeight={false}
                            inputProps={{
                                onChange: (e) => {
                                    handleTransferBookingInfoInput(e);
                                },
                                type: "text",
                                autoComplete: "on",
                                name: "departureAirportFrom",
                                required: true,
                                value: transferBookingInfo?.departureAirportFrom || "",
                                placeholder: t("t_b_bf_from_airport_code"),
                                className: styles.inputField,
                                disabled: true
                            }}
                            className={styles.ArrivalDepartureInput}
                        />

                        <Input
                            variants={{labelPosition: "outlined"}}
                            fullHeight={false}
                            inputProps={{
                                onChange: (e) => {
                                    handleTransferBookingInfoInput(e);
                                },
                                type: "text",
                                autoComplete: "on",
                                name: "departureAirportTo",
                                required: true,
                                value: transferBookingInfo?.departureAirportTo || "",
                                placeholder: t("t_b_bf_to_airport_code"),
                                className: styles.inputField
                            }}
                            className={styles.ArrivalDepartureInput}
                        />

                        <Input
                            variants={{labelPosition: "outlined"}}
                            fullHeight={false}
                            inputProps={{
                                onChange: (e) => {
                                    handleTransferBookingInfoInput(e);
                                },
                                type: "text",
                                autoComplete: "on",
                                name: "departureFlightNumber",
                                required: true,
                                value: transferBookingInfo?.departureFlightNumber || "",
                                placeholder: t("t_b_bf_flight_number"),
                                className: styles.inputField
                            }}
                            className={styles.ArrivalDepartureInput}
                        />

                        <Input
                            variants={{labelPosition: "outlined"}}
                            fullHeight={false}
                            inputProps={{
                                onChange: (e) => {
                                    handleTransferBookingInfoInput(e);
                                },
                                type: "text",
                                autoComplete: "on",
                                name: "departureAirlineName",
                                required: true,
                                value: transferBookingInfo?.departureAirlineName || "",
                                placeholder: t("t_b_bf_departure_airline_name"),
                                className: styles.inputField
                            }}
                            className={styles.ArrivalDepartureInput}
                        />
                    </div>
                )}

                <div className={styles.ContactPersonInputGroupContainer}>
                    <h4 className={styles.ContactPersonHeading}>{t("t_b_bf_contact_person")}</h4>

                    <BasicDropdown
                        required={true}
                        style={{width: 80}}
                        dropDownOptions={titles.titles.map(({
                                                                label,
                                                                value
                                                            }) => ({
                            value,
                            label: t(label) as unknown as string
                        }))}
                        onDropdownChange={(value) => handleContactInfo({
                            target: {
                                value,
                                name: "title"
                            }
                        })}
                        dropDownValue={title}
                        className={styles.ContactPersonTitleDropdown}
                    />

                    <Input
                        variants={{labelPosition: "outlined"}}
                        fullHeight={false}
                        validator={(e) => validateName(e)}
                        inputProps={{
                            onChange: (e) => {
                                handleContactInfo(e);
                            },
                            type: "text",
                            autoComplete: "given-name",
                            name: "firstname",
                            required: true,
                            value: firstname || "",
                            placeholder: t("t_b_bf_first_name")
                        }}
                        className={styles.ContactPersonName}
                    />

                    <Input
                        variants={{labelPosition: "outlined"}}
                        fullHeight={false}
                        validator={(e) => validateName(e)}
                        inputProps={{
                            onChange: (e) => {
                                handleContactInfo(e);
                            },
                            type: "text",
                            autoComplete: "family-name",
                            name: "lastname",
                            required: true,
                            value: lastname || "",
                            placeholder: t("t_b_bf_last_name")
                        }}
                        className={styles.ContactPersonSurname}
                    />

                    <Input
                        variants={{labelPosition: "outlined"}}
                        fullHeight={false}
                        inputProps={{
                            onChange: (e) => {
                                handleContactInfo(e);
                            },
                            type: "email",
                            autoComplete: "email",
                            name: "email",
                            required: true,
                            pattern: EMAIL_PATTERN,
                            value: email || "",
                            placeholder: t("t_b_bf_email")
                        }}
                        className={styles.ContactPersonEmail}
                    />

                    <Input
                        variants={{labelPosition: "outlined"}}
                        fullHeight={false}
                        inputProps={{
                            onChange: (e) => {
                                handleContactInfo(e);
                            },
                            type: "tel",
                            autoComplete: "tel",
                            name: "phone",
                            required: true,
                            pattern: PHONE_PATTERN,
                            value: phone || "",
                            placeholder: t("t_b_bf_phone_number")
                        }}
                        className={styles.ContactPersonPhone}
                    />

                    <CustomCheckBox
                        className={styles.ProvideInvoiceRecipientToggle}
                        inputProps={{
                            name: "customInvoice",
                            checked: customInvoice,
                            onChange: () => dispatch(toggleInvoiceRecipient())
                        }}
                        label={t("t_b_bf_provide_invoice_recipient")}
                    />

                    {customInvoice && (<BookingInvoiceRecipient
                        handleInvoiceRecipient={handleCustomInvoiceRecipient}
                        invoiceRecipient={invoiceRecipient}
                    />)}
                </div>

                {paymentOptions?.smsReminderEnabled && paymentOptions?.displaySmsFee && (
                    <div className={styles.SmsReminderInputGroup}>
                        <h4 className={styles.SmsReminderHeading}>{t("t_b_bf_pickup_information")}</h4>
                        <div className={styles.SmsReminderText}>{t("t_b_bf_order_pickup_info_by_sms_notice")}</div>

                        <CustomCheckBox
                            inputProps={{
                                name: "smsOrdered",
                                checked: transferBookingInfo?.smsOrdered,
                                onChange: toggleSmsOrdered
                            }}
                            label={t("t_b_bf_pickup-info") + " (" + getPriceWithCurrencySymbol(paymentOptions?.displaySmsFee || 0, currency) + ")"}
                            className={styles.SmsReminderToggle}
                        />

                        {transferBookingInfo?.smsOrdered && (
                            <div className={styles.SmsReminderInputs}>
                                <Input
                                    variants={{labelPosition: "outlined"}}
                                    fullHeight={false}
                                    inputProps={{
                                        onChange: (e) => {
                                            handleTransferBookingInfoInput(e);
                                        },
                                        type: "text",
                                        autoComplete: "tel",
                                        name: "smsReminderNumber",
                                        required: true,
                                        pattern: PHONE_PATTERN,
                                        value: transferBookingInfo.smsReminderNumber || "",
                                        placeholder: t("t_b_bf_clients_telephone_number"),
                                        className: styles.inputField
                                    }}
                                    className={styles.SmsReminderInput}
                                />

                                {paymentOptions?.smsLocales && (
                                    <SmsLanguage
                                        fullHeight={false}
                                        setTextInputValue={(textInputValue) => handleInputChange("smsLanguagesmsReminderLocaleInput", textInputValue)}
                                        textInputValue={transferBookingInfo.smsReminderLocale}
                                        handleSmsLocaleChange={(value) => handleTransferBookingInfoInput({
                                            target: {
                                                value,
                                                name: "smsReminderLocale"
                                            }
                                        })}
                                        options={paymentOptions?.smsLocales}
                                        className={styles.SmsReminderInput}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                )}

                <div className={styles.ReminderInputGroup}>
                    <h4 className={styles.ReminderGroupHeading}>{t("t_b_bf_memo")}</h4>

                    <Input
                        variants={{labelPosition: "outlined"}}
                        fullHeight={false}
                        inputProps={{
                            onChange: (e) => {
                                handleMemo(e);
                            },
                            type: "text",
                            autoComplete: "on",
                            name: "memo",
                            value: memo || "",
                            placeholder: t("t_b_bf_memo_input_placeholder")
                        }}
                    />

                    <p>{t("h_b_bf_transfer_memo_desc")}</p>
                </div>

                <div
                    style={{
                        marginBottom: 40
                    }}
                    className="h-divider"
                />

                <ConfirmOrder
                    data={{
                        allowToBook: paymentOptions?.allowToBook || false,
                        allowSplitPayment: paymentOptions?.allowSplitPayment || false,
                        agency: paymentOptions?.agency || false,
                        defaultEmail: paymentOptions?.defaultEmail || "",
                        defaultPhone: paymentOptions?.defaultPhone || "",
                        errors,
                        agreeTerms
                    }}
                    toggleAgreeTerms={() => dispatch(toggleAgreeTerms())}
                />

                <Pricing
                    hidePricingInformation={true}
                    withButton={paymentOptions?.allowToBook || paymentOptions.allowSplitPayment}
                    displaySalePrice={paymentOptions?.displaySalePrice || 0}
                    salePrice={paymentOptions?.salePrice}
                    smsFee={paymentOptions?.smsFee}
                    displaySmsFee={paymentOptions?.displaySmsFee}
                    paymentPending={paymentOptions?.paymentPending}
                />
            </form>
        </div>
    );
}

export default BookingForm;