import HotelSearchResultsTypes from "redux/constants/hotelSearchResults.constants";
import _ from "lodash";
import {ReduxHotelSearchResultsPropTypes} from "proptypes/PropTypeRedux";
import {
    HotelDetails,
    HotelOfferPropTypes,
    ReviewRatingFilterPropTypes,
    RoomOfferPropTypes
} from "proptypes/PropTypeObjects";
import HotelSearchResultsActions from "./actions/hotelSearchResults.reducer.actions";
import {compareNumbers} from "utils/sortFunction";

export const emptyReviewRatingFilter = {
    fromRating: [],
    slider: false,
    range: {
        min: 5,
        max: 10
    }
} as ReviewRatingFilterPropTypes;

export const emptyFilters = {
    hotelNameFilter: "",
    offerProviderFilter: "",
    roomTypeFilter: "",
    priceFilter: undefined,
    boardTypeFilter: [],
    hotelCategoryFilter: [],
    propertyTypeFilter: [],
    reviewRatingFilter: emptyReviewRatingFilter,
    featuresFilter: [],
    specialOffersFilter: false,
    memberOnlyFilter: false,
    onlyGoodCXLFilter: false,
    onlyRefundableFilter: false,
    onlyNonRefundableFilter: false
};

export const INITIAL_STATE = {
    afterAllFilters: [],
    sortedHotelIds: [],
    dataForFilters: [],
    hotelOffers: [],
    allHotelOffers: [],
    allProvidersWithOffers: [],
    statistics: undefined,
    hotelDetailsCache: [],
    allResultsCount: 0,
    firstTimeMounted: false,
    activeFilters: [],
    activeStateFilters: [],
    size: 20,
    pageSizeOptions: [
        {
            value: 10,
            label: 10
        },
        {
            value: 20,
            label: 20
        },
        {
            value: 50,
            label: 50
        }
    ],
    pageNumber: 1,
    pagesCount: 1,
    maxPrice: undefined,
    maxAllowed: undefined,
    sortBy: {
        label: "Price",
        value: "price"
    },
    sortByOptions: [
        {
            value: "price"
        },
        {
            value: "stars"
        },
        {
            value: "hotelName"
        }
    ],
    filters: {
        ...emptyFilters
    },
    stateFilters: {
        ...emptyFilters
    },
    mapFilter: false,
    favoriteFilter: false,
    submitedFilters: true,
    isUpdating: false,
    requestingDetails: false,
    cxlUpdatingRoomIds: [],
    view: "detailed"
} as ReduxHotelSearchResultsPropTypes;

export default (state = INITIAL_STATE, action: HotelSearchResultsActions): ReduxHotelSearchResultsPropTypes => {
    switch (action.type) {
    case HotelSearchResultsTypes.CLEAR_STATE:
        return {
            ...INITIAL_STATE,
            maxAllowed: undefined,
            maxPrice: undefined,
            hotelDetailsCache: state.hotelDetailsCache,
            stateFilters: {
                ...state.stateFilters,
                priceFilter: undefined
            },
            filters: {
                ...state.filters,
                priceFilter: undefined
            },
            activeFilters: [],
            activeStateFilters: [],
            pageNumber: 1
        };
    case HotelSearchResultsTypes.FIRST_TIME_MOUNTED: {
        return {
            ...state,
            firstTimeMounted: true
        };
    }
    case HotelSearchResultsTypes.FILTERS_SUBMITED: {
        return {
            ...state,
            submitedFilters: true
        };
    }
    case HotelSearchResultsTypes.SEARCH_RESULTS_RECIEVED:
        return {
            ...state,
            hotelDetailsCache: action.hotelOffers.map((e) => ({hotel: e.hotel}) as HotelDetails),
            hotelOffers: action.hotelOffers,
            allHotelOffers: action.allHotelOffers,
            allProvidersWithOffers: action.allProvidersWithOffers,
            dataForFilters: [...action.allHotelOffers],
            allResultsCount: action.allResultsCount,
            favoriteFilter: false
        };
    case HotelSearchResultsTypes.START_GETTING_DETAILS:
        return {
            ...state,
            requestingDetails: true
        };
    case HotelSearchResultsTypes.DETAILS_NOT_RECIEVED:
        return {
            ...state,
            error: action.error,
            requestingDetails: false
        };
    case HotelSearchResultsTypes.RECEIVED_HOTEL_DETAILS_CACHE:
        return {
            ...state,
            hotelDetailsCache: [...state.hotelDetailsCache, ...action.newHotelDetails]
        };
    case HotelSearchResultsTypes.CLEAR_HOTEL_DETAILS_CACHE:
        return {
            ...state,
            hotelDetailsCache: []
        };

    case HotelSearchResultsTypes.RECEIVED_DETAILS:
        return {
            ...state,
            hotelDetailsCache: action.newHotelDetails ? [...state.hotelDetailsCache, ...action.newHotelDetails] : state.hotelDetailsCache,
            hotelOffers: [...action.requestedHotelDetails
                .sort((a, b) => compareNumbers(state.sortedHotelIds.indexOf(a.hotel.id), state.sortedHotelIds.indexOf(b.hotel.id)))
                .map((hotel: HotelDetails) => {
                    for (let index = 0; index < state.dataForFilters.length; index++) {
                        if (Number((state.dataForFilters[index]).hotel.id) === Number(hotel.hotel.id)) {
                            return {
                                ...state.dataForFilters[index],
                                ...hotel
                            };
                        }
                    }
                    return false;
                })
                .filter((hotel) => hotel) as HotelOfferPropTypes[]],
            requestingDetails: false
        };
    case HotelSearchResultsTypes.HANDLE_STATE_FILTERS:
        return {
            ...state,
            stateFilters: {
                ...action.filters,
                priceFilter: {
                    ...action.filters,
                    min: action.filters.priceFilter?.min && action.filters.priceFilter.min === 0 ? undefined : action.filters.priceFilter?.min,
                    max: action.filters.priceFilter?.max && action.filters.priceFilter?.max === state.maxAllowed ? undefined : action.filters.priceFilter?.max
                }
            },
            activeStateFilters: Object.entries(action.filters)
                .map(([key, value]) => {
                    if (!key) {
                        return undefined;
                    }

                    const min = action.filters.priceFilter?.min;
                    const max = action.filters.priceFilter?.max;
                    if (key === "priceFilter" && (!min || min === 0) && (!max || max === state.maxPrice)) {
                        return undefined;
                    }
                    if (key === "reviewRatingFilter" && !action.filters.reviewRatingFilter.slider && action.filters.reviewRatingFilter.fromRating.length === 0) {
                        return undefined;
                    }
                    if (typeof value === "boolean") {
                        return value === true ? key : undefined;
                    }
                    if (_.isEmpty(value)) {
                        return undefined;
                    }

                    return key;
                })
                .filter((value) => value !== undefined),
            submitedFilters: false
        };
    case HotelSearchResultsTypes.HANDLE_FILTERS:
        return {
            ...state,

            submitedFilters: false
        };
    case HotelSearchResultsTypes.UPDATE_FILTERS:
        return {
            ...state,
            filters: action.filters ? action.filters : state.stateFilters,
            activeFilters: action.activeFilters ? action.activeFilters : state.activeStateFilters,
            isUpdating: true
        };
    case HotelSearchResultsTypes.START_CXL_UPDATE:
        return {
            ...state,
            ...(action.addRoomId && {
                cxlUpdatingRoomIds: [...state.cxlUpdatingRoomIds, action.roomId]
            })
        };
    case HotelSearchResultsTypes.UPDATE_CXL:
        return {
            ...state,
            hotelOffers: state.hotelOffers.map(({hotel, roomOffers}) => ({
                hotel,
                roomOffers: (roomOffers).map((roomItem: RoomOfferPropTypes[], i: number) => roomItem.map((room: RoomOfferPropTypes, i: number) => (room.roomId === action.roomId
                    ? {
                        ...room,
                        cancellationPolicy: action.availabilities[0]?.terms.cancellationPolicy || {
                            cancellationRules: [],
                            cxlDate: ""
                        }
                    }
                    : room)))
            })),
            cxlUpdatingRoomIds: action.addRoomId
                ? state.cxlUpdatingRoomIds.filter((rId) => rId !== action.roomId)
                : state.cxlUpdatingRoomIds
        };
    case HotelSearchResultsTypes.UPDATE_CXL_REST:
        return {
            ...state,
            dataForFilters: state.dataForFilters.map(({hotel, roomOffers}) => ({
                hotel,
                roomOffers: (roomOffers).map((roomItem: RoomOfferPropTypes[], i: number) => roomItem.map((room: RoomOfferPropTypes, i: number) => (room.roomId === action.roomId
                    ? {
                        ...room,
                        cancellationPolicy: action.availabilities[0]?.terms.cancellationPolicy || {
                            cancellationRules: [],
                            cxlDate: ""
                        }
                    }
                    : room)))
            })),
            allHotelOffers: state.allHotelOffers.map(({hotel, roomOffers}) => ({
                hotel,
                roomOffers: (roomOffers).map((roomItem: RoomOfferPropTypes[], i: number) => roomItem.map((room: RoomOfferPropTypes, i: number) => (room.roomId === action.roomId
                    ? {
                        ...room,
                        cancellationPolicy: action.availabilities[0]?.terms.cancellationPolicy || {
                            cancellationRules: [],
                            cxlDate: ""
                        }
                    }
                    : room)))
            }))
        };
    case HotelSearchResultsTypes.UPDATE_CXL_FAILED:
        return {
            ...state,
            cxlUpdatingRoomIds: action.addRoomId
                ? state.cxlUpdatingRoomIds.filter((rId) => rId !== action.roomId)
                : state.cxlUpdatingRoomIds
        };
    case HotelSearchResultsTypes.FILTERS_UPDATED:
        return {
            ...state,
            isUpdating: false
        };
    case HotelSearchResultsTypes.ADD_FILTER:
        return {
            ...state,
            afterAllFilters: action.dataForFilters ? [...action.dataForFilters] : state.afterAllFilters,
            dataForFilters: action.dataForFilters,
            allResultsCount: action.dataForFilters?.length || 0
        };
    case HotelSearchResultsTypes.ADD_STATISTICS:
        return {
            ...state,
            statistics: action.statistics
        };
    case HotelSearchResultsTypes.REQ_MAP_FILTER:
        return {
            ...state,
            isUpdating: true
        };
    case HotelSearchResultsTypes.REQ_TOGGLE_FAVORITE:
        return {
            ...state,
            isUpdating: true
        };
    case HotelSearchResultsTypes.REQ_TOGGLE_FAVORITE_COMPLETE:
        return {
            ...state,
            isUpdating: false
        };
    case HotelSearchResultsTypes.ADD_MAP_FILTER:
        return {
            ...state,
            dataForFilters: action.dataForFilters,
            allResultsCount: action.dataForFilters.length,
            isUpdating: false,
            mapFilter: true
        };
    case HotelSearchResultsTypes.REMOVE_MAP_FILTER:
        return {
            ...state,
            mapFilter: false
        };
    case HotelSearchResultsTypes.RESET_FILTER_DATA:
        return {
            ...state,
            dataForFilters: [...state.allHotelOffers],
            allResultsCount: state.allHotelOffers.length
            // isUpdating: false
        };
    case HotelSearchResultsTypes.GET_MAX_PRICE: {
        return {
            ...state,
            maxPrice: action.maxPrice,
            maxAllowed: action.maxAllowed,
            filters: {
                ...state.filters,
                priceFilter: {
                    ...state.filters,
                    min: state.filters.priceFilter && state.filters.priceFilter?.min === 0 ? undefined : state.filters.priceFilter?.min,
                    max: state.filters.priceFilter && state.filters.priceFilter.max === state.maxAllowed ? undefined : state.filters.priceFilter?.max
                }
            },
            stateFilters: {
                ...state.stateFilters,
                priceFilter: {
                    ...state.filters,
                    min: state.stateFilters.priceFilter && state.stateFilters.priceFilter?.min === 0 ? undefined : state.stateFilters.priceFilter?.min,
                    max: state.stateFilters.priceFilter && state.stateFilters.priceFilter.max === state.maxAllowed ? undefined : state.stateFilters.priceFilter?.max
                }
            }
        };
    }
    case HotelSearchResultsTypes.CLEAR_FILTERS:
        return {
            ...state,
            dataForFilters: [...state.allHotelOffers],
            activeFilters: [],
            maxPrice: undefined,
            maxAllowed: undefined,
            filters: {
                ...emptyFilters,
                priceFilter: undefined
            },
            activeStateFilters: [],
            stateFilters: {
                ...emptyFilters,
                priceFilter: undefined
            },
            allResultsCount: state.allHotelOffers.length,
            isUpdating: false,
            submitedFilters: false,
            favoriteFilter: false
        };
    case HotelSearchResultsTypes.GET_PAGES_COUNT:
        return {
            ...state,
            pagesCount: action.pagesCount
        };
    case HotelSearchResultsTypes.CHANGE_PAGE_NUMBER:
        return {
            ...state,
            pageNumber: action.pageNumber > state.pagesCount ? 1 : action.pageNumber
        };
    case HotelSearchResultsTypes.CHANGE_SORT_VALUE:
        return {
            ...state,
            sortBy: action.sortBy
        };
    case HotelSearchResultsTypes.CHANGE_SORT:
        return {
            ...state,
            dataForFilters: action.dataForFilters,
            sortedHotelIds: action.dataForFilters.map((t) => t.hotel.id)
        };
    case HotelSearchResultsTypes.CHANGE_PAGE_SIZE:
        return {
            ...state,
            size: action.size
        };
    case HotelSearchResultsTypes.CHANGE_VIEW:
        return {
            ...state,
            view: action.view
        };
    case HotelSearchResultsTypes.ADD_FAVORITE_FILTER:
        return {
            ...state,
            dataForFilters: action.dataForFilters,
            favoriteFilter: true,
            allResultsCount: action.dataForFilters.length
        };
    case HotelSearchResultsTypes.REMOVE_FAVORITE_FILTER:
        return {
            ...state,
            favoriteFilter: false
        };
    case HotelSearchResultsTypes.TOGGLE_FAVORITE:
        return {
            ...state,
            allHotelOffers: state.allHotelOffers.map((item) => ((item).hotel.id === action.id
                ? {
                    ...item,
                    favourite: !item.favourite
                }
                : item)),
            dataForFilters: state.dataForFilters.map((item) => ((item).hotel.id === action.id
                ? {
                    ...item,
                    favourite: !item.favourite
                }
                : item)),
            hotelOffers: state.hotelOffers.map((item) => ((item).hotel.id === action.id
                ? {
                    ...item,
                    favourite: !item.favourite
                }
                : item))
        };
    default:
        return state;
    }
};
