import React, {ChangeEvent, Component} from "react"
import {WithTranslation, withTranslation} from "react-i18next";
import * as searchActions from "redux/actions/hotelSearch.actions";
import * as resultsActions from "redux/actions/hotelSearchResults.actions";
import getNationality from "redux/actions/nationality.actions";
import {getDestinations} from "redux/actions/destinations.actions";
import {batch, connect, ConnectedProps} from "react-redux";
import {changeCurrentCompanyMarkupIndex} from "redux/actions/auth.actions";
import {RootState} from "redux/store/store.init";
import {debounce, DebouncedFunc, isEqual} from "lodash";
import initQuickFilters from "utils/quickFilters";
import {submitFilters, updateFilters, clearFilters} from "redux/actions/hotelSearch.base.actions";
import {
    DestinationDestinationPropTypes,
    HotelOfferPropTypes, HotelSearchCriteriaPropTypes, RadiusDestinationPropTypes,
    RecentDestinationPropTypes,
    ReduxModalType
} from "proptypes/PropTypeObjects"
import SearchBox from "./SearchBox";

type SearchBoxContainerProps = ConnectedProps<typeof connector> & WithTranslation & {modal?: ReduxModalType};
type SearchBoxContainerState = {
    backup: any;
    boardTypes: {value: string | number; label: string;}[];
    features: {value: number; label: string;}[];
};

class SearchBoxContainer extends Component<SearchBoxContainerProps, SearchBoxContainerState> {
    debouncedGetDestinations: DebouncedFunc<(value?: string | undefined) => Promise<void>>;

    constructor(props: SearchBoxContainerProps) {
        super(props);
        const {t, getDestinations} = props;
        this.state = {
            backup: {
                nationalityInput: null,
                destinationInput: null,
                currentDestination: {},
                formData: null,
                activeFilters: null,
                filters: null
            },
            ...initQuickFilters(t)
        };

        this.debouncedGetDestinations = debounce((value) => getDestinations(value), 300, {
            leading: false,
            trailing: true
        });
    }

    componentDidMount() {
        this.setStateBackup();

        const {
            search: {
                nationalityInput: oldNatInput
                // formData: {
                //     cityId
                // }
            },
            getNationality: nationality
            // handleUpdateDestination
        } = this.props;

        nationality(!oldNatInput, !!oldNatInput);
        // if (cityId) {
        //     handleUpdateDestination();
        // }
    }

    componentDidUpdate(prevProps: SearchBoxContainerProps) {
        const {
            searchResults: {
                submitedFilters, filters
            },
            search: {
                formData, submitedSearch
            },
            locale,
            currency,
            searchUpdate,
            hotelAmount
        } = this.props;

        const prevFilters = prevProps.searchResults.filters;
        const prevFormData = prevProps.search.formData;

        if (prevProps.locale !== locale) {
            searchUpdate();
        }

        if (prevProps.currency !== currency) {
            searchUpdate();
        }

        // if (prevProps.hotelAmount !== hotelAmount) {
        //     this.handleSubmitFilterUpdate();
        // }

        if (
            submitedFilters &&
            submitedSearch &&
            (!isEqual(filters, prevFilters) || !isEqual(formData, prevFormData))
        ) {
            this.setStateBackup();
        }
    }

    setStateBackup = () => {
        const {
            searchResults: {
                filters, activeFilters
            },
            search: {
                formData,
                nationalityInput,
                destinationInput,
                currentDestination
            }
        } = this.props;

        this.setState({
            backup: {
                formData,
                filters,
                activeFilters,
                nationalityInput,
                destinationInput,
                currentDestination
            }
        });
    };

    handleSubmitSearch = () => {
        const {searchUpdate} = this.props;
        searchUpdate(false, true, true, true);
    };

    handleSubmitFilterUpdate = () => {
        const {updateFilters} = this.props;
        updateFilters();
    };

    handleNatChangeContainer = (clientNationality: string, nationalityInput: string) => {
        const {
            handleInputChange,
            handleNatChange
        } = this.props;

        batch(() => {
            handleInputChange("nationalityInput", nationalityInput);
            handleNatChange(clientNationality);
        });
    };

    resetNationalities = () => {
        const {handleNatChange} = this.props;
        handleNatChange(undefined);
    };

    resetDestinations = () => {
        const {
            changeCurrentDestination,
            handleDestinations
        } = this.props;
        changeCurrentDestination(undefined);
        handleDestinations({
            cityId: undefined,
            hotelId: undefined,
            airportId: undefined,
            radius: undefined
        } as HotelSearchCriteriaPropTypes);
    };

    handleDestinationsContainer = (destination: DestinationDestinationPropTypes | RecentDestinationPropTypes | undefined, destinationInput: string, group: string) => {
        const {
            changeCurrentDestination,
            handleInputChange,
            handleDestinations
        } = this.props;
        changeCurrentDestination(destination);

        if (group === "destinations") {
            handleInputChange("destinationInput", destinationInput);
            handleDestinations({
                cityId: (destination as DestinationDestinationPropTypes).id,
                hotelId: undefined,
                airportId: undefined,
                radius: undefined
            } as HotelSearchCriteriaPropTypes);
        }
        if (group === "hotels") {
            handleInputChange("destinationInput", destinationInput);
            handleDestinations({
                cityId: undefined,
                hotelId: (destination as DestinationDestinationPropTypes).id,
                airportId: undefined,
                radius: undefined
            } as HotelSearchCriteriaPropTypes);
        }
        if (group === "airports") {
            handleInputChange("destinationInput", destinationInput);
            handleDestinations({
                cityId: undefined,
                hotelId: undefined,
                airportId: (destination as DestinationDestinationPropTypes).id,
                radius: undefined
            } as HotelSearchCriteriaPropTypes);
        }
        if (group === "radius") {
            handleInputChange("destinationInput", destinationInput);
            handleDestinations({
                cityId: undefined,
                hotelId: undefined,
                airportId: undefined,
                radius: (destination as RadiusDestinationPropTypes).radius
            } as HotelSearchCriteriaPropTypes);
        }
        if (group === "recents") {
            handleInputChange("destinationInput", destinationInput);
            handleDestinations({
                // @ts-ignore
                cityId: destination.cityId ? destination.cityId : undefined,
                // @ts-ignore
                hotelId: destination.hotelId ? destination.hotelId : undefined,
                // @ts-ignore
                airportId: destination.airportId ? destination.airportId : undefined,
                // @ts-ignore
                radius: destination.radius ? destination.radius : undefined
            } as HotelSearchCriteriaPropTypes);
        }
    };

    handleInputChangeContainer = (e: ChangeEvent<HTMLInputElement>) => {
        const {
            handleInputChange
        } = this.props;

        const {name, value} = e.target;

        if (name === "destinationInput") {
            void this.debouncedGetDestinations(value);
        }
        handleInputChange(name, value);
    };

    handleBoardType = (value: number | string, isActive: boolean) => {
        const {handleBoardTypeFilter} = this.props;
        handleBoardTypeFilter(value, isActive);
    };

    handleFeatures = (value: number, isActive: boolean) => {
        const {handleFeatureFilter} = this.props;
        handleFeatureFilter(value, isActive);
    };

    render() {
        const {
            search: {
                formData: {
                    clientNationality,
                    hotelId,
                    cityId, airportId, radius
                },
                stateFormData: {
                    checkIn: stateCheckIn,
                    checkOut: stateCheckOut,
                    rooms: stateRooms
                },
                nationalityInput,
                destinationInput
            },
            searchResults: {
                filters: {
                    priceFilter
                },
                stateFilters, maxPrice, maxAllowed, allHotelOffers
            },
            destinations: {
                destinations
            },
            nationality: {
                nationalities
            },
            handleDateChange,
            handleAdultsChange,
            handleChildrenChange,
            handleAddRoom,
            handleRemoveRoom,

            handleFilterInputChange,
            handleHotelCategory,
            handlePropertyType,
            handleReviewRating,
            handleSpecialOffersFilter,
            handleMemberOnlyFilter,
            handleOnlyGoodCXLFilter,
            handleOnlyRefundableFilter,
            handleOnlyNonRefundableFilter,
            handlePriceFilterChange,
            clearFilters,
            currency,
            currentDestination,

            markups,
            currentMarkup,
            changeCurrentCompanyMarkupIndex
        } = this.props;

        const {
            boardTypes,
            features
        } = this.state;

        return (
            <SearchBox
                data={{
                    destinations,
                    nationalities,
                    clientNationality,
                    nationalityInput,
                    destinationInput,
                    hotelId,
                    cityId,
                    airportId,
                    radius,
                    boardTypes,
                    features,
                    stateCheckIn,
                    stateCheckOut,
                    rooms: stateRooms,
                    stateFilters,
                    currency,
                    maxPrice,
                    maxAllowed: maxAllowed || 1000,
                    propertyTypes: [...new Set((allHotelOffers as HotelOfferPropTypes[]).map((e: HotelOfferPropTypes) => e.hotel.propertyType))] as string[],
                    priceFilter
                }}
                getDestinations={this.debouncedGetDestinations}
                handleFeatures={this.handleFeatures}
                handleBoardType={this.handleBoardType}
                handleDestinations={this.handleDestinationsContainer}
                handleInputChange={this.handleInputChangeContainer}
                handleNatChange={this.handleNatChangeContainer}
                handleSubmitFilterUpdate={this.handleSubmitFilterUpdate}
                handleSubmitSearch={this.handleSubmitSearch}
                handleDateChange={handleDateChange}
                handleFilterInputChange={handleFilterInputChange}
                handleHotelCategory={handleHotelCategory}
                handlePropertyType={handlePropertyType}
                handleReviewRating={handleReviewRating}
                handleSpecialOffersFilter={handleSpecialOffersFilter}
                handleMemberOnlyFilter={handleMemberOnlyFilter}
                handleOnlyGoodCXLFilter={handleOnlyGoodCXLFilter}
                handleOnlyRefundableFilter={handleOnlyRefundableFilter}
                handleOnlyNonRefundableFilter={handleOnlyNonRefundableFilter}
                handlePriceFilterChange={handlePriceFilterChange}
                handleAdultsChange={handleAdultsChange}
                handleChildrenChange={handleChildrenChange}
                handleAddRoom={handleAddRoom}
                handleRemoveRoom={handleRemoveRoom}
                clearFilters={clearFilters}
                currentDestination={currentDestination}
                markups={markups}
                currentMarkup={currentMarkup}
                changeCurrentCompanyMarkupIndex={changeCurrentCompanyMarkupIndex}
            />
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    markups: state.auth.userData?.companyMarkups,
    currentMarkup: state.auth.currentCompanyMarkupIndex,
    search: state.hotelSearch,
    currentDestination: state.hotelSearch.currentDestination,
    searchResults: state.hotelSearchResults,
    dataForFilters: state.hotelSearchResults.dataForFilters,
    destinations: state.destinations,
    nationality: state.nationality,
    locale: state.locale.currentLocale,
    currency: state.currency.currentCurrency,
    hotelAmount: state.auth.userData?.companyMarkups[state.auth.currentCompanyMarkupIndex || 0].hotelAmount || 1
});

const connector = connect(mapStateToProps, {
    ...searchActions,
    getDestinations,
    getNationality,
    ...resultsActions,
    updateFilters,
    submitFilters,
    clearFilters,
    changeCurrentCompanyMarkupIndex
});

export default withTranslation()(connector(SearchBoxContainer));
