import {
    AmendmentDetailsStatusResponse, ItemModificationResponse,
    WebTransferAmendRequest
} from "proptypes/PropTypeObjects";
import formatToLTDate from "../utils/internationalization/formatToLTDate";
import {ConstrainedAxiosRequestConfig} from "./axios/axiosController";

export function getAmendmentTypes(id: number, locale: string): ConstrainedAxiosRequestConfig<WebTransferAmendRequest> {
    return {
        method: "get",
        url: `/app/amendment/transfer/${id}`,
        headers: {
            Accept: "application/json"
        },
        params: {
            locale
        },
        maxNumRequests: 1
    };
}

export function addAmendments(formData: WebTransferAmendRequest, id: number, locale: string): ConstrainedAxiosRequestConfig<ItemModificationResponse, WebTransferAmendRequest> {
    return {
        method: "put",
        url: `/app/amendment/transfer/${id}`,
        headers: {
            Accept: "application/json"
        },
        data: {
            ...formData,
            amendments: {
                ...formData.amendments,
                FLIGHT_DATETIME_ARRIVAL: formatToLTDate(formData.amendments.FLIGHT_DATETIME_ARRIVAL as number),
                FLIGHT_DATETIME_DEPARTURE: formatToLTDate(formData.amendments.FLIGHT_DATETIME_DEPARTURE as number)
            }
        },
        params: {
            locale
        },
        maxNumRequests: 1
    };
}

export function acceptAmendment(amendment: number, id: number, locale: string): ConstrainedAxiosRequestConfig<AmendmentDetailsStatusResponse> {
    return {
        method: "post",
        url: `/app/amendment/transfer/${id}/item/${amendment}`,
        headers: {
            Accept: "application/json"
        },
        params: {
            locale
        },
        maxNumRequests: 1
    };
}

export function cancelAmendment(maxNumRequests: number, amendment: number, id: number, locale: string): ConstrainedAxiosRequestConfig<AmendmentDetailsStatusResponse> {
    return {
        method: "delete",
        url: `/app/amendment/transfer/${id}/item/${amendment}`,
        headers: {
            Accept: "application/json"
        },
        params: {
            locale
        },
        maxNumRequests: 1
    };
}
