import {acceptAmendment, cancelAmendment} from "api/amendmentsAPI";
import {BookingDetailsTypes} from "../constants/bookingDetails.constants";

import * as bookingDetailsAPI from "../../api/bookingDetailsAPI";
import {
    AmendmentDetailsStatusResponse,
    HotelFlightDetailsPropTypes,
    SpecialRequestsPropTypes,
    WebHotelBookingDetails,
    WebTransferBookingDetails
} from "proptypes/PropTypeObjects";
import {CommonActionReturnType} from "../store/store.init";
import IAppActions from "../store/store.actions";
import {globalAxiosController} from "api/axios/axiosInstance";
import Currency from "@hotelston_web_frontend_parent/src/constants/currency";

function getDetails(paymentOrderId: number, bookingDetailsParams?: {
    locale: string,
    currency: Currency
}): IAppActions {
    return ({
        type: BookingDetailsTypes.GET_DETAILS,
        paymentOrderId,
        bookingDetailsParams
    });
}

function getDetailsSuccess(data: WebTransferBookingDetails | WebHotelBookingDetails, bookingReferences?: string[], multiBooking?: boolean): IAppActions {
    return ({
        type: BookingDetailsTypes.GET_DETAILS_SUCCESS,
        multiBooking: multiBooking || false,
        bookingReferences,
        data
    });
}

function getDetailsFailure(error: string): IAppActions {
    return ({
        type: BookingDetailsTypes.GET_DETAILS_FAILURE,
        error
    });
}

export function getBookingDetails(paymentOrderId: number): CommonActionReturnType {
    return (dispatch, getState): void => {
        const locale = getState().locale.currentLocale;
        const currency = getState().currency.currentCurrency;
        const subAgent = getState().auth.userData?.subAgent || false;

        dispatch(getDetails(paymentOrderId, {locale, currency}));

        globalAxiosController.addRequest(
            bookingDetailsAPI.getBookingDetails(
                paymentOrderId,
                locale,
                currency
            )
        ).then((data) => {
            const {
                bookingDetails,
                bookingReferences,
                multiBooking = false
            } = data;

            if (subAgent && bookingDetails && bookingDetails.paymentPending) {
                dispatch(getDetailsSuccess({
                    ...bookingDetails,
                    paymentPending: undefined
                }, bookingReferences, multiBooking));
            } else {
                let alteredData = {...bookingDetails};
                if (!alteredData.paymentPending) {
                    const foundWorkaroundBooking = getState().myBookings.data?.bookings.find((booking) => booking.id === bookingDetails.id)

                    if (foundWorkaroundBooking) {
                        alteredData.paymentPending = foundWorkaroundBooking.paymentPending;
                    }
                }

                dispatch(getDetailsSuccess(alteredData, bookingReferences, multiBooking));
            }
        }).catch((error: unknown) => {
            dispatch(getDetailsFailure(error as string));
        });
    };
}

export function getBooking(bookingId: number, callback?: () => void): CommonActionReturnType {
    return (dispatch, getState) => {
        const locale = getState().locale.currentLocale;
        const currency = getState().currency.currentCurrency;
        const subAgent = getState().auth.userData?.subAgent || false;

        dispatch(getDetails(bookingId, {locale, currency}));
        globalAxiosController.addRequest(bookingDetailsAPI.getBooking(bookingId, locale, currency))
            .then((data: WebTransferBookingDetails | WebHotelBookingDetails) => {
                if (subAgent && data && data.paymentPending) {
                    dispatch(getDetailsSuccess({
                        ...data,
                        paymentPending: undefined
                    }));
                } else {
                    let alteredData = {...data};
                    if (!data.paymentPending) {
                        const foundWorkaroundBooking = getState().myBookings.data?.bookings.find((booking) => booking.id === bookingId)

                        if (foundWorkaroundBooking) {
                            alteredData.paymentPending = foundWorkaroundBooking.paymentPending;
                        }
                    }

                    dispatch(getDetailsSuccess(alteredData));
                }
            }).catch((error: unknown) => {
                dispatch(getDetailsFailure(error as string));
            }).finally(() => {
                if (callback) {
                    callback();
                }
            });
    };
}

function setBookingData(bookingId: number, data: WebTransferBookingDetails | WebHotelBookingDetails, locale: string, currency: Currency): IAppActions {
    return ({
        type: BookingDetailsTypes.SET_BOOKING_DATA,
        bookingId,
        data,
        bookingDetailsParams: {
            locale,
            currency
        }
    });
}

export function setBooking(bookingId: number, data: WebTransferBookingDetails | WebHotelBookingDetails): CommonActionReturnType {
    return (dispatch, getState) => {
        const locale = getState().locale.currentLocale;
        const currency = getState().currency.currentCurrency;

        dispatch(setBookingData(bookingId, data, locale, currency));
    };
}

function cancelBookingStart(): IAppActions {
    return ({
        type: BookingDetailsTypes.CANCEL_BOOKING
    });
}

function cancelBookingSuccess(): IAppActions {
    return ({
        type: BookingDetailsTypes.CANCEL_BOOKING_SUCCESS
    });
}

function cancelBookingFailure(error: string): IAppActions {
    return ({
        type: BookingDetailsTypes.CANCEL_BOOKING_FAILURE,
        error
    });
}

export function cancelBooking(id: number): CommonActionReturnType {
    return (dispatch) => {
        dispatch(cancelBookingStart());
        globalAxiosController.addRequest(bookingDetailsAPI.cancelBooking(id))
            .then((data) => {
                if (data) {
                    dispatch(updateBooking());
                    dispatch(cancelBookingSuccess());
                } else {
                    dispatch(cancelBookingFailure(""));
                }
            }).catch((error: unknown) => {
                dispatch(cancelBookingFailure(error as string));
            });
    };
}

export function updateBooking(): CommonActionReturnType {
    return (dispatch, getState) => {
        const {bookingId} = getState().bookingDetails;

        if (bookingId) {
            dispatch(getBooking(bookingId));
        }
    };
}

export function updateBookingDetails(): CommonActionReturnType {
    return (dispatch, getState) => {
        const {paymentOrderId} = getState().bookingDetails;

        if (paymentOrderId) {
            dispatch(getBookingDetails(paymentOrderId));
        }
    };
}

function updateAmendmentData(amendmentId: number, amendmentStatus: AmendmentDetailsStatusResponse): IAppActions {
    return ({
        type: BookingDetailsTypes.UPDATE_AMENDMENT_DATA,
        amendmentId,
        amendmentStatus: amendmentStatus.status,
        amendmentStatusText: amendmentStatus.statusText
    });
}

export function acceptAmendmentAndUpdateStatus(amendmentId: number, hotelId: number, callback?: (success: boolean, errors?: string[]) => void): CommonActionReturnType {
    return (dispatch, getState) => {
        const locale = getState().locale.currentLocale;

        globalAxiosController.addRequest(acceptAmendment(amendmentId, hotelId, locale))
            .then((amendmentStatus) => {
                if (callback) {
                    callback(true);
                }

                dispatch(updateAmendmentData(amendmentId, amendmentStatus));
            }).catch((error: unknown) => {
                if (callback) {
                    callback(false, [error as string]);
                }
            });
    };
}

function updateAmendmentStatus(amendmentId: number, amendmentStatus: AmendmentDetailsStatusResponse): IAppActions {
    return ({
        type: BookingDetailsTypes.UPDATE_AMENDMENT_DATA,
        amendmentId,
        amendmentStatus: amendmentStatus.status,
        amendmentStatusText: amendmentStatus.statusText
    });
}

export function cancelAmendmentAndUpdateStatus(amendmentId: number, hotelId: number, maxRequestCount?: number, callback?: (success: boolean, errors?: string[]) => void): CommonActionReturnType {
    return (dispatch, getState) => {
        const locale = getState().locale.currentLocale;
        globalAxiosController.addRequest(cancelAmendment(maxRequestCount || 1, amendmentId, hotelId, locale))
            .then((amendmentStatus) => {
                if (callback) {
                    callback(true);
                }

                dispatch(updateAmendmentStatus(amendmentId, amendmentStatus));
            }).catch((error: unknown) => {
                if (callback) {
                    callback(false, [error as string]);
                }
            });
    };
}

export function resetDetails(): IAppActions {
    return {
        type: BookingDetailsTypes.RESET_DETAILS
    };
}

function setMemo(): IAppActions {
    return ({
        type: BookingDetailsTypes.REQ_SET_MEMO
    });
}

function setMemoSuccess(memo?: string): IAppActions {
    return ({
        type: BookingDetailsTypes.SET_MEMO_SUCCESS,
        memo
    });
}

function setMemoFailure(): IAppActions {
    return ({
        type: BookingDetailsTypes.SET_MEMO_FAILURE
    });
}

export function setBookingMemo(memo?: string): CommonActionReturnType {
    return (dispatch, getState) => {
        if (!memo) {
            return;
        }

        const id = getState().bookingDetails.bookingId || getState().bookingDetails.data?.id;

        dispatch(setMemo());

        globalAxiosController.addRequest(bookingDetailsAPI.setBookingMemo(Number(id), memo)).then(() => {
            dispatch(setMemoSuccess(memo));
        }).catch((error: unknown) => {
            // console.log(error);
            dispatch(setMemoFailure());
        });
    };
}

export function setBookingSpecialRequests(specialRequests: SpecialRequestsPropTypes): IAppActions {
    return {
        type: BookingDetailsTypes.SET_SPECIAL_REQUESTS,
        specialRequests
    };
}

export function setFlightDetails(flightDetails: HotelFlightDetailsPropTypes): IAppActions {
    return {
        type: BookingDetailsTypes.SET_FLIGHT_DETAILS_SUCCESS,
        flightDetails
    };
}

export function setShowBusinessCredentialsToFalse(): IAppActions {
    return {
        type: BookingDetailsTypes.SET_SHOW_CREDENTIALS_TO_FALSE
    };
}

export function updateRetailPrice(newAmount: number): IAppActions {
    return {
        type: BookingDetailsTypes.UPDATE_RETAIL_PRICE,
        newAmount
    };
}